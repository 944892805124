import styled from 'styled-components';

export const MultiSelectBarWrapper = styled.div`
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  width: 515px;
  font-size: 14px;
  font-weight: 600;

  background-color: rgb(108, 114, 157);
  padding: 8px 16px;
  border-radius: 6px;
  color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 48px;
  gap: 20px;

  z-index: 9999;
`;

export const Separator = styled.div`
  width: 1px;
  height: 20px;
  background-color: #ffffff;
  opacity: 0.3;
  margin: 0 8px;
`;

// Each clickable item in the bar
export const ActionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    opacity: 0.8;
  }
`;

// Three sections: left, center, right
export const LeftSection = styled.div`
  flex: 2;
  display: flex;
  align-items: center;
  font-weight: 600;
`;

export const CountBadge = styled.span`
  background-color: white;
  color: rgb(108, 114, 157);
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 600;
`;

export const CenterSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export const RightSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
