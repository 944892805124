import React, { useEffect, useState } from 'react';
import { theme, notification, Spin, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { setPageInfo } from '../../store/ducks/Global/operations';
import { setFileData } from '../../store/ducks/File/operations';
import { File } from '../../store/ducks/File/types';
import GroupedCards from '../../components/GroupedCards';
import {
  getAllFilesData,
  getFilteredFilesData,
  markAllAsRead,
  markAsRead,
} from '../../services/filesService/filesService.service';
import { useLocation } from 'react-router-dom';

const FileComponent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { token } = theme.useToken();
  const isDarkMode =
    useSelector((state: RootState) => state.global.uitheme) === 'dark';
  const selectedCompany = useSelector(
    (state: RootState) => state.global.selectedCompany
  );
  const [filesData, setFilesData] = useState<File[]>([]);
  const [loading, setLoading] = useState(true);
  // Manage the current filter using Tabs: default is 'new'
  const [filterOption, setFilterOption] = useState<'new' | 'all'>('new');

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    width: '65%',
    minWidth: 'fit-content',
    backgroundColor: isDarkMode ? '#0F0F1A' : '#FCFCFC',
    padding: '40px 36px',
    marginBottom: '61px',
    borderRadius: '10px',
    display: 'grid',
    gap: '26px',
    height: '100%',
  };

  useEffect(() => {
    dispatch(
      setPageInfo({ currentPageName: 'Files', numberOfElem: filesData.length })
    );
  }, [dispatch, filesData.length]);

  useEffect(() => {
    dispatch(setFileData(filesData));
  }, [dispatch, filesData]);

  const fetchFiles = async () => {
    setLoading(true);
    try {
      let data: File[];
      if (filterOption === 'new') {
        // Pass a query string to get filtered (i.e. new/unread) files
        const queryParams = new URLSearchParams();
        queryParams.append('ipMetadataGuIds', 'new');
        const queryString = queryParams.toString();
        data = await getFilteredFilesData(queryString);
      } else {
        data = await getAllFilesData();
      }
      const sortedData = data.sort((a: File, b: File) => b.date - a.date);
      setFilesData(sortedData);
    } catch (error: any) {
      notification.error({
        message: 'Request failed',
        description: 'Failed to get files data',
      });
    } finally {
      setLoading(false);
    }
  };

  // Call fetchFiles when the filter option or company changes
  useEffect(() => {
    fetchFiles();
  }, [filterOption, selectedCompany]);

  const onMarkAllAsRead = async () => {
    setLoading(true);
    try {
      await markAllAsRead();
      await fetchFiles(); // Refetch files after marking all as read
      notification.success({
        message: 'Success',
        description: 'All files marked as read.',
      });
    } catch (error: any) {
      notification.error({
        message: 'Request failed',
        description: 'Failed to mark all files as read',
      });
    }
  };

  const onMarkAsRead = async (guid: string) => {
    setLoading(true);
    try {
      await markAsRead(guid);
      await fetchFiles(); // Refetch files after marking a single file as read
    } catch (error: any) {
      notification.error({
        message: 'Request failed',
        description: 'Failed to mark file as read',
      });
    }
  };

  // Group files into unread and read (grouped by year)
  const groupData = (data: File[]) => {
    const unreadFiles = data
      .filter((file) => !file.isRead)
      .sort((a, b) => b.date - a.date);
    const readFiles = data.filter((file) => file.isRead);
    const groupedReadFiles = readFiles.reduce(
      (acc: Record<string, File[]>, file) => {
        const year = new Date(file.date).getFullYear().toString();
        if (!acc[year]) acc[year] = [];
        acc[year].push(file);
        return acc;
      },
      {}
    );
    return { unreadFiles, groupedReadFiles };
  };

  const { unreadFiles, groupedReadFiles } = groupData(filesData);

  const centeredContainerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '200px',
    textAlign: 'center',
    color: isDarkMode ? 'white' : '',
  };

  return (
    <div style={contentStyle}>
      <Tabs
        activeKey={filterOption}
        onChange={(key) => setFilterOption(key as 'new' | 'all')}
      >
        <Tabs.TabPane
          key='new'
          tab={
            <div
              style={{
                width: '50px',
                textAlign: 'center',
                color:
                  filterOption !== 'new' && isDarkMode ? 'white' : 'inherit',
              }}
            >
              New
            </div>
          }
        >
          {loading ? (
            <div style={centeredContainerStyle}>
              <Spin tip='Loading...' size='large' />
            </div>
          ) : unreadFiles.length === 0 ? (
            <div style={centeredContainerStyle}>
              All files are read. Please click&nbsp;
              <span
                style={{
                  color: isDarkMode ? 'gray' : 'blue',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => setFilterOption('all')}
              >
                here
              </span>
              &nbsp;to see all files.
            </div>
          ) : (
            <GroupedCards
              unreadFiles={unreadFiles}
              groupedReadFiles={groupedReadFiles}
              isDarkMode={isDarkMode}
              isFiles={true}
              onMarkAllAsRead={onMarkAllAsRead}
              onMarkAsRead={onMarkAsRead}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane
          key='all'
          tab={
            <div
              style={{
                width: '50px',
                textAlign: 'center',
                color:
                  filterOption !== 'all' && isDarkMode ? 'white' : 'inherit',
              }}
            >
              All
            </div>
          }
        >
          {loading ? (
            <div style={centeredContainerStyle}>
              <Spin tip='Loading...' size='large' />
            </div>
          ) : (
            <GroupedCards
              unreadFiles={unreadFiles}
              groupedReadFiles={groupedReadFiles}
              isDarkMode={isDarkMode}
              isFiles={true}
              onMarkAllAsRead={onMarkAllAsRead}
              onMarkAsRead={onMarkAsRead}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default FileComponent;
