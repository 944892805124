// EmailLoginPage.tsx
import React, { useState } from 'react';
import { Input, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { requestEmailOtp } from '../../services/authenticationService/emailOtpService';
import { StyledButton, StyledFormItem } from '../../views/Login/Login.styled';

const EmailLoginPage = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSendOtp = async () => {
    if (!email) return;

    setLoading(true);
    try {
      await requestEmailOtp(email);
      navigate('/login/email/verify', { state: { email } });
    } catch (error: any) {
      notification.error({
        message: 'Failed to send OTP',
        description:
          error.response?.data?.message || error.message || 'Unknown error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <button
          onClick={() => navigate('/login')}
          style={{
            background: 'none',
            border: 'none',
            fontSize: '16px',
            cursor: 'pointer',
            color: '#1677ff',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          ← Back
        </button>
      </div>
      <h3>Sign in with Email</h3>
      <p>Enter your email to receive a one-time password (OTP).</p>
      <StyledFormItem
        name='otpEmail'
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input
          placeholder='Enter your email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </StyledFormItem>
      <StyledButton
        type='primary'
        size='large'
        onClick={handleSendOtp}
        loading={loading}
      >
        {loading ? 'Sending...' : 'Send OTP'}
      </StyledButton>
    </div>
  );
};

export default EmailLoginPage;
