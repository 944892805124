import React from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store/configureStore';
import { ReactComponent as ShortCutIcon } from '../../assets/icons/shortCutIcon.svg';
import {
  IconContainer,
  ShortcutButton,
  ShortcutsWrapper,
  ShortcutsTitle,
  SeeAllButton,
} from './SearchShortcuts.styled';
import { ShortCutType } from '../../utils/searchShortcuts';

export enum SearchShortcut {
  IP_RIGHTS = 'IP_RIGHTS',
  ACTIVE = 'ACTIVE',
  PENDING_APPLICATIONS = 'PENDING_APPLICATIONS',
  EXPIRED = 'EXPIRED',
  CLOSED = 'CLOSED',
}

interface SearchShortcutsProps {
  title: string;
  activeShortcuts: ShortCutType[];
  handleShortcutClick: (shortcut: ShortCutType) => void;
}

const SearchShortcuts: React.FC<SearchShortcutsProps> = ({
  title,
  activeShortcuts,
  handleShortcutClick,
}) => {
  const { uitheme } = useSelector((state: RootState) => state.global);
  const isDarkMode = uitheme === 'dark';
  const navigate = useNavigate();

  // Function to render the shortcuts as individual buttons
  const renderShortcuts = (shortcuts: ShortCutType[]) => {
    return shortcuts.map((shortcut) => (
      <ShortcutButton
        key={shortcut.label}
        onClick={() => handleShortcutClick(shortcut)}
        isDarkMode={isDarkMode}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <span
            style={{
              fontSize: '14px',
              color: isDarkMode ? '#6C729D' : '#656a92',
            }}
          >
            Search shortcuts
          </span>
          <span style={{ fontSize: '22px', fontWeight: '500' }}>
            {shortcut.label}
          </span>
        </div>
        <IconContainer isDarkMode={isDarkMode}>
          <ShortCutIcon />
        </IconContainer>
      </ShortcutButton>
    ));
  };

  return (
    <>
      {/* Title and See All Button */}
      <Button
        shape='round'
        size='large'
        style={{
          color: isDarkMode ? '#CBD2D9' : '#363853',
          background: isDarkMode ? '#0F0F1A' : 'none',
          border: isDarkMode ? 'none' : '1px solid #CACACA',
          fontWeight: 600,
          width: '76px',
          height: '32px',
        }}
        onClick={() => {
          navigate('/ip');
        }}
      >
        See all
      </Button>
      {/* Wrapper for the shortcuts to adjust their layout */}
      <ShortcutsWrapper>
        {/* Render shortcuts as individual items */}
        {renderShortcuts(activeShortcuts)}
      </ShortcutsWrapper>
    </>
  );
};

export default SearchShortcuts;
