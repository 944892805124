import { environment } from '../../environments/environment';
import { fetchWithCompanyId } from '../../utils/fetchWithCompanyId';
import authHeader from '../authenticationService/auth-header';

export const getAllFilesData = async () => {
  try {
    return await fetchWithCompanyId('/files', {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching files data: ' + error.message);
  }
};

export const getFilteredFilesData = async (queryString: string) => {
  try {
    return await fetchWithCompanyId(`/files?${queryString}`, {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching files data: ' + error.message);
  }
};

export const getSingleFileData = async (fileName: string) => {
  try {
    const response = await fetch(`${environment.apiUrl}/files?id=${fileName}`, {
      headers: authHeader(),
    });
    // if (!response.ok) {
    //   throw new Error('Network response was not ok');
    // }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching files data: ' + error.message);
  }
};

export const downloadFile = async (fileName: string): Promise<Response> => {
  try {
    const response = await fetch(
      `${environment.apiUrl}/files/download?fileName=${fileName}`,
      {
        headers: authHeader(),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response;
  } catch (error: any) {
    throw new Error('Error fetching file: ' + error.message);
  }
};

export const markAsRead = async (guid: string) => {
  try {
    return await fetchWithCompanyId(`/files/${guid}/markAsRead`, {
      method: 'POST',
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error updating file status: ' + error.message);
  }
};

export const markAllAsRead = async () => {
  try {
    return await fetchWithCompanyId('/files/markAllAsRead', {
      method: 'POST',
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error marking all files as read: ' + error.message);
  }
};
