import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Tag, Tooltip, Image } from 'antd';
import styled from 'styled-components';

interface StyledElementProps {
  isDarkMode: boolean;
}

const StyledElement = styled(Card)<StyledElementProps>`
  width: 100%;
  max-width: 350px;
  margin: auto;
  height: 450px;
  border: ${({ isDarkMode }) =>
    isDarkMode ? '2px solid #2F3049' : '2px solid #E5E5E5'};
  box-shadow: 0px 8px 16px 0px #00000014;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#0F0F1A' : '#F5F5F5')};
  border-radius: 10px;
  .ant-card-head {
    padding: 0;
    border: none;
  }
  .ant-card-head-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .button-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-right: 20px;
    }
  }
  .ellipsis-content {
    max-width: 320px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;
    &.sub {
      -webkit-line-clamp: 3 !important;
    }
  }
`;

const OuterContainer = styled.div`
  display: flex;
  padding: 15px;
  padding-left: 0;
  align-items: center; /* Center content vertically */
  justify-content: flex-start; /* Align text to the left */
  height: 60px; /* Set the container height */
`;

const StyledTag = styled.span<StyledElementProps>`
  font-size: 16px;
  font-weight: 600;
  color: ${({ isDarkMode }) => (isDarkMode ? '#ffffff' : '#0f0f1a')};
  border-radius: 0px 15px 15px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
  padding: 0 15px;
  width: auto;
  height: 30px;
`;

const Description = styled.span`
  flex: 0.8;
  text-align: left;
`;

const Date = styled.span<StyledElementProps>`
  text-align: left;
  color: ${({ isDarkMode }) => isDarkMode && '#a3a7c3'};
`;

const StyledList = styled.ul`
  max-width: 320px;
  font-size: 16px;
  color: #656a92;
  margin: 0;
  padding: 0 15px;
`;

const ListItemContent = styled.div`
  display: flex;
  gap: 10;
`;

interface StyledCardProps {
  isDarkMode: boolean;
  property: any;
  thumbnailURL: string | null;
  navigateToPropertyDetails: (property: any) => void;
  // New optional props:
  isSelected?: boolean;
  onToggleSelect?: () => void;
}

const StyledCard: React.FC<StyledCardProps> = ({
  isDarkMode,
  property,
  thumbnailURL,
  navigateToPropertyDetails,
  isSelected = false,
  onToggleSelect,
}) => {
  const parsedEvents = property.card.event || []; // Assuming 'event' is an array
  const [isTruncated, setIsTruncated] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const threshold = 29;
  const status = property.card?.status || 'no status';
  const textRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (property.card?.title?.length > threshold) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [property.card?.title, threshold]);

  const statusColorMap: Record<
    string,
    { lightMode: string; darkMode: string }
  > = {
    'no status': { lightMode: '#D6BA7D', darkMode: '#897B4B' },
    Unfiled: { lightMode: '#D6BA7D', darkMode: '#897B4B' },
    Pending: { lightMode: '#AABBCA', darkMode: '#476E79' },
    Published: { lightMode: '#BCC9B0', darkMode: '#4A6651' },
    Registered: { lightMode: '#99A9A5', darkMode: '#5E7471' },
    'Allow to lapse': { lightMode: '#98878D', darkMode: '#6E5B5E' },
    Opposed: { lightMode: '#D1A69B', darkMode: '#675077' },
    'Opposition Pending': { lightMode: '#D1A69B', darkMode: '#675077' },
    'Provisional refusal': { lightMode: '#D1A69B', darkMode: '#675077' },
    'File Closed': { lightMode: 'transparent', darkMode: 'transparent' },
    'Withdrawn / Renunciation': {
      lightMode: 'transparent',
      darkMode: 'transparent',
    },
  };

  const getStatusColor = (status: string, isDarkMode: boolean) => {
    const defaultColors = { lightMode: '#E0E0E0', darkMode: '#000000' };
    const colors = statusColorMap[status] || defaultColors;
    return isDarkMode ? colors.darkMode : colors.lightMode;
  };

  const statusColor = getStatusColor(status, isDarkMode);

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      // Using timeout to ensure the DOM has updated
      setTimeout(() => {
        const isOverflowing = element.scrollHeight > element.clientHeight;
        setIsTruncated(isOverflowing);
      }, 100); // Delay might be necessary to allow for layout updates
    }
  }, [property.card]);

  return (
    <StyledElement
      isDarkMode={isDarkMode}
      title={
        <>
          <OuterContainer>
            <StyledTag
              style={{ backgroundColor: 'transparent' }}
              isDarkMode={isDarkMode}
            >
              {property?.card?.status}
            </StyledTag>
          </OuterContainer>
          <div className='button-wrapper'>
            <Button
              shape='round'
              style={{
                height: 30,
                color: '#656A92',
                border: '2px solid #656A92',
                background: isDarkMode ? '#0F0F1A' : '#ffffff',
                fontWeight: 600,
              }}
              size={'small'}
              onClick={() => navigateToPropertyDetails(property)}
            >
              View
            </Button>
            {/* Circle button for multi-select */}
            <Button
              shape='circle'
              size='small'
              style={{
                width: 26,
                height: 26,
                border: '2px solid #656A92',
                background: isDarkMode ? '#0F0F1A' : '#ffffff',
                // Ensures we can center the small circle inside
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={(e) => {
                e.stopPropagation(); // don't trigger "View" if we click the circle
                onToggleSelect && onToggleSelect();
              }}
            >
              {isSelected && (
                <div
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    // You can pick a color that contrasts with the button background
                    backgroundColor: '#656A92',
                  }}
                />
              )}
            </Button>
          </div>
        </>
      }
      bodyStyle={{ padding: '20px', fontWeight: 700 }}
      headStyle={{
        background: statusColor,
        borderRadius: '8px',
        height: '75px',
        borderBottom: isDarkMode ? '1px solid #2F3049' : '1px solid #E5E5E5',
      }}
      size='small'
    >
      <div
        style={{
          color: isDarkMode ? 'white' : '#2C2F35',
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
        }}
      >
        <div style={{ borderBottom: '1px solid #B7B7B7' }}>
          <div
            style={{
              gap: '10px',
              display: 'flex',
              color: 'white',
              fontSize: 20,
              marginBottom: '10px',
            }}
          >
            {thumbnailURL && (
              <Image
                style={{ width: '54px', height: '54px' }}
                src={thumbnailURL}
              />
            )}
            <Tooltip
              title={showTooltip ? property?.card?.title : ''}
              color='#6C729D'
            >
              <span
                ref={textRef}
                className='ellipsis-content'
                style={{
                  color: isDarkMode ? '#FFFFFF' : '#656A92',
                  width: '100%',
                  lineHeight: '25px',
                }}
              >
                {property.card?.title}
              </span>
            </Tooltip>
          </div>
          <p
            className='ellipsis-content sub'
            style={{
              fontSize: 19,
              margin: '10px 0',
              color: isDarkMode ? '#FFFFFF' : '#7E7E7E',
            }}
          >
            {property.card?.subtitle}
          </p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          {property.card.userTags && property.card.userTags.length > 0 ? (
            <p
              className='ellipsis-content'
              style={{
                margin: 0,
                fontWeight: 400,
                fontSize: 17,
                color: isDarkMode ? '#FFFFFF' : '#7E7E7E',
              }}
            >
              {property.card.userTags.map((tag: any) => (
                <Tag
                  key={tag.guid}
                  style={{
                    fontSize: '16px',
                    padding: '4px 8px',
                    color: 'gray',
                    marginTop: '5px',
                  }}
                >
                  {tag.name}
                </Tag>
              ))}
            </p>
          ) : (
            <p
              style={{
                color: 'rgb(217, 217, 217)',
                fontSize: '15px',
              }}
            >
              No business information available
            </p>
          )}
          {parsedEvents.length > 0 && (
            <StyledList>
              {parsedEvents.map(
                (
                  event: {
                    description:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<
                          any,
                          string | React.JSXElementConstructor<any>
                        >
                      | Iterable<React.ReactNode>
                      | React.ReactPortal
                      | null
                      | undefined;
                    date:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<
                          any,
                          string | React.JSXElementConstructor<any>
                        >
                      | Iterable<React.ReactNode>
                      | React.ReactPortal
                      | null
                      | undefined;
                  },
                  index: React.Key | null | undefined
                ) => (
                  <li key={index}>
                    <ListItemContent>
                      <Description>{event.description}</Description>
                      <Date isDarkMode={isDarkMode}>{event.date}</Date>
                    </ListItemContent>
                  </li>
                )
              )}
            </StyledList>
          )}
        </div>
      </div>
    </StyledElement>
  );
};

export default StyledCard;
