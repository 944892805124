import { environment } from '../../environments/environment';
import { fetchWithCompanyId } from '../../utils/fetchWithCompanyId';
import authHeader from '../authenticationService/auth-header';

export const getCardsData = async () => {
  try {
    return await fetchWithCompanyId('/ip/cards', {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching cards data: ' + error.message);
  }
};

export const getSingleCardData = async (guid: string) => {
  try {
    return await fetchWithCompanyId(`/ip/cards/${guid}`, {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching card data: ' + error.message);
  }
};

export const getFilteredCardsData = async (queryString: string) => {
  try {
    return await fetchWithCompanyId(`/ip/cards?${queryString}`, {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching filtered cards data: ' + error.message);
  }
};

export const getIpDataByCountryCoverage = async (queryString: string) => {
  try {
    return await fetchWithCompanyId(`/ip?${queryString}`, {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching filtered cards data: ' + error.message);
  }
};

export const getCardDetailsData = async (guid: any) => {
  try {
    return await fetchWithCompanyId(`/ip/details/${guid}`, {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching card details data: ' + error.message);
  }
};

export const cardImageUrlGeneration = async (fileName: any) => {
  try {
    const response = await fetch(
      `${environment.apiUrl}/media/image-urls?name=${fileName}`,
      {
        headers: authHeader(),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching image data: ' + error.message);
  }
};

export const detailsImageUrlGeneration = async (fileName: any) => {
  try {
    const response = await fetch(
      `${environment.apiUrl}/media/image-urls?name=${fileName}`,
      {
        headers: authHeader(),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching image data: ' + error.message);
  }
};

export const getAllBusinessTags = async () => {
  try {
    return await fetchWithCompanyId('/tags', {
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json', // Ensure correct content type
      },
    });
  } catch (error: any) {
    throw new Error('Error fetching tags: ' + (error?.message || error));
  }
};

export const createBusinessTag = async (tagObject: {
  name: string;
  tagGroupGuid: string;
}) => {
  try {
    return await fetchWithCompanyId('/tags', {
      method: 'POST',
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json', // Ensure correct content type
      },
      body: JSON.stringify(tagObject),
    });
  } catch (error: any) {
    throw new Error('Error saving tags: ' + (error?.message || error));
  }
};

export const updateBusinessTags = async (tagGuids: string[], guid: string) => {
  try {
    return await fetchWithCompanyId(`/ip/${guid}/tags`, {
      method: 'POST',
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json', // Ensure correct content type
      },
      body: JSON.stringify({ tagGuids: tagGuids }),
    });
  } catch (error: any) {
    // Ensure we extract a message if possible
    throw new Error('Error updating tags: ' + (error?.message || error));
  }
};

export const getAllTagGroups = async () => {
  try {
    return await fetchWithCompanyId('/tags/groups', {
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json', // Ensure correct content type
      },
    });
  } catch (error: any) {
    throw new Error('Error fetching groups: ' + (error?.message || error));
  }
};

export const createNewTagGroup = async (groupName: string) => {
  try {
    return await fetchWithCompanyId('/tags/groups', {
      method: 'POST',
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json', // Ensure correct content type
      },
      body: JSON.stringify({ name: groupName }),
    });
  } catch (error: any) {
    // Ensure we extract a message if possible
    throw new Error('Error updating tags: ' + (error?.message || error));
  }
};
