import { environment } from '../../environments/environment';
import authHeader from '../authenticationService/auth-header';

export const fetchUserData = async () => {
  const userData = localStorage.getItem('loginInfo');
  let userId = '';
  if (userData) userId = JSON.parse(userData)?.userId;

  try {
    const response = await fetch(`${environment.apiUrl}/users/${userId}`, {
      headers: authHeader(),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching user data: ' + error.message);
  }
};
