import { notification } from 'antd';

const currentUrl = window.location.hostname;
const isArnoldSiedsma = currentUrl.includes('arnold-siedsma');
const clientId = process.env.REACT_APP_SSO_LINKEDIN_CLIENT_ID ?? '';
const redirectUri =
  process.env.REACT_APP_CI_ENVIRONMENT_NAME === 'Develop'
    ? !isArnoldSiedsma
      ? process.env.REACT_APP_SSO_DEV_LINKEDIN_REDIRECT_URL
      : process.env.REACT_APP_SSO_LINKEDIN_REDIRECT_URL_AS
    : isArnoldSiedsma
    ? process.env.REACT_APP_SSO_LINKEDIN_REDIRECT_URL_AS
    : process.env.REACT_APP_SSO_LINKEDIN_REDIRECT_URL;

const linkedinAuthority = process.env.REACT_APP_SSO_LINKEDIN_AUTHORITY ?? '';

export const initiateLinkedinAuthentication = () => {
  try {
    const responseType = 'code';
    const scopes = ['openid', 'profile', 'email'];
    let authUrl = `${linkedinAuthority}`;

    authUrl += `?client_id=${encodeURIComponent(clientId)}`;
    authUrl += `&response_type=${encodeURIComponent(responseType)}`;
    authUrl += `&redirect_uri=${encodeURIComponent(redirectUri || '')}`;
    authUrl += `&scope=${encodeURIComponent(scopes.join(' '))}`;

    window.location.href = authUrl;
  } catch (error: any) {
    notification.error({
      message: 'Linkedin Sign In failed',
      description:
        error.message || 'An error occurred during Linkedin Sign In.',
    });
  }
};

export const processLinkedinResponse = async (codeParam: string) => {
  try {
    const loginInfo = await loginWithCode(codeParam);
    return loginInfo;
  } catch (error: any) {
    console.log('error in processLinkedinResponse catch:', error);
    throw error;
  }
};

export const loginWithCode = async (code: string) => {
  try {
    const response = await fetch(
      `https://auth.ipmanagement.io/auth/linkedin-login-callback?code=${code}`
    );

    if (!response.ok) {
      const errorData = await response.json();
      console.log('response error', errorData, errorData.error_message);
      throw new Error(errorData.error_message || errorData.error);
    }

    const userData = await response.json();
    const loginInfo = {
      userId: userData.userId,
      accessToken: userData.accessToken,
    };
    localStorage.setItem('loginInfo', JSON.stringify(loginInfo));
    return userData;
  } catch (error: any) {
    console.log('error in linkedin loginWithCode catch:', error);
    throw error;
  }
};
