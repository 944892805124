import React from 'react';
import DataCard from './DataCard';
import { ReactComponent as NewTag } from '../assets/icons/newTag.svg';
import { Button } from 'antd';

interface GroupedCardsProps {
  groupedData?: Record<string, any[]>; // For financial data grouped by year
  unreadFiles?: any[]; // For unread files
  groupedReadFiles?: Record<string, any[]>; // For read files grouped by year
  isDarkMode: boolean;
  isFiles?: boolean; // Flag to determine whether we are displaying files
  onMarkAllAsRead?: () => void;
  onMarkAsRead?: (guid: string) => void; // Ensure onMarkAsRead takes `guid` to identify the file
}

const GroupedCards: React.FC<GroupedCardsProps> = ({
  groupedData,
  unreadFiles,
  groupedReadFiles,
  isDarkMode,
  isFiles,
  onMarkAllAsRead,
  onMarkAsRead,
}) => {
  // Sorting keys for financial data or groupedReadFiles
  const sortedGroupKeys = groupedData
    ? Object.keys(groupedData).sort((a, b) => b.localeCompare(a))
    : groupedReadFiles
    ? Object.keys(groupedReadFiles).sort((a, b) => b.localeCompare(a))
    : [];

  const hasUnreadFiles = unreadFiles && unreadFiles.length > 0;

  return (
    <div>
      {isFiles && unreadFiles && unreadFiles.length > 0 && (
        <div style={{ marginBottom: '15px', position: 'relative' }}>
          {unreadFiles.map((item) => (
            <div
              key={item.guid}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginBottom: '10px',
              }}
            >
              {!item.isRead && (
                <div
                  style={{
                    width: '27px',
                    height: '27px',
                    position: 'relative',
                  }}
                >
                  <NewTag />
                </div>
              )}
              <DataCard
                data={item}
                isDarkMode={isDarkMode}
                isFiles={isFiles}
                onMarkAsRead={() => onMarkAsRead && onMarkAsRead(item.guid)}
              />
            </div>
          ))}

          {/* "Mark All as Read" button for unread files */}
          {isFiles && hasUnreadFiles && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '15px',
              }}
            >
              <Button
                ghost
                onClick={() => onMarkAllAsRead && onMarkAllAsRead()}
                style={{
                  color: '#6C729D',
                  border: 'none',
                  padding: 0,
                }}
              >
                mark all as read
              </Button>
            </div>
          )}
        </div>
      )}

      {/* Render grouped read files for files component */}
      {isFiles && groupedReadFiles && sortedGroupKeys.length > 0 && (
        <div>
          {sortedGroupKeys.map((year) => (
            <div
              key={year}
              style={{ marginBottom: '15px', position: 'relative' }}
            >
              {groupedReadFiles[year].map((item: any) => (
                <div
                  key={item.guid}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <DataCard
                    data={item}
                    isDarkMode={isDarkMode}
                    isFiles={isFiles}
                    onMarkAsRead={onMarkAsRead}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      {/* Render grouped data for financials (groupedData) */}
      {!isFiles && groupedData && sortedGroupKeys.length > 0 && (
        <div>
          {sortedGroupKeys.map((year) => (
            <div key={year} style={{ marginBottom: '15px' }}>
              <div
                style={{
                  color: '#656A92',
                  border: '1px solid #656A92',
                  height: '30px',
                  width: '70px',
                  borderRadius: '29px',
                  fontSize: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '15px',
                }}
              >
                {year}
              </div>
              {groupedData[year].map((item: any) => (
                <div
                  key={item.guid}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    marginBottom: '10px',
                  }}
                >
                  <DataCard data={item} isDarkMode={isDarkMode} />
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GroupedCards;
