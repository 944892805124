import { environment } from '../environments/environment';

export const fetchWithCompanyId = async (
  url: string,
  options?: RequestInit
) => {
  const selectedCompany = localStorage.getItem('selectedCompany') || '';
  const urlObj = new URL(url, `${environment.apiUrl}`);
  if (selectedCompany) {
    urlObj.searchParams.append('companyGuId', selectedCompany);
  }

  const updatedUrl = urlObj.toString();

  const response = await fetch(updatedUrl, options);

  if (!response.ok) {
    throw new Error(`Fetch error: ${response.statusText}`);
  }

  const contentType = response.headers.get('Content-Type') || '';
  if (contentType.includes('application/json')) {
    return response.json();
  }

  return response.text();
};
