import React, { useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps';
import { Card, Button, Spin, Empty } from 'antd';
import GeoJSON from '../../testJson/GeoJSON.json'; // Adjust to your GeoJSON file path

interface ChoroplethMapProps {
  data: { groupName: string | null; count: number }[];
  loading: boolean;
  onCountryClick: (country: string) => void; // New prop
}

const ChoroplethMap: React.FC<ChoroplethMapProps> = ({
  data,
  loading,
  onCountryClick,
}) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [center] = useState<[number, number]>([0, 20]);
  const [tooltipData, setTooltipData] = useState<string | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  const highlightedCountries = data
    .filter((item) => item.groupName)
    .map((item) => item.groupName!);

  const handleZoomIn = () => {
    if (zoomLevel >= 4) return;
    setZoomLevel(zoomLevel * 1.5);
  };

  const handleZoomOut = () => {
    if (zoomLevel <= 1) return;
    setZoomLevel(zoomLevel / 1.5);
  };

  return (
    <Card
      title='IP Assets'
      style={{
        width: '88%',
        minWidth: '500px',
        height: 'auto',
        position: 'relative',
      }}
      bodyStyle={{ padding: 0 }}
    >
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1000,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
          }}
        >
          <Spin size='large' />
        </div>
      ) : !loading && !highlightedCountries.length ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1000,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
          }}
        >
          <Empty description='No data to display' />
        </div>
      ) : null}

      <div
        style={{
          position: 'absolute',
          bottom: 20,
          left: 20,
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}
      >
        <Button
          onClick={handleZoomIn}
          disabled={loading}
          style={{
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            fontSize: '18px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
          }}
        >
          +
        </Button>
        <Button
          onClick={handleZoomOut}
          disabled={loading}
          style={{
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            fontSize: '18px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
          }}
        >
          -
        </Button>
      </div>

      {!loading && tooltipData && (
        <div
          style={{
            position: 'absolute',
            left: `${tooltipPosition.x + 10}px`,
            top: `${tooltipPosition.y + 10}px`,
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            color: 'white',
            padding: '5px 10px',
            borderRadius: '4px',
            fontSize: '12px',
            pointerEvents: 'none',
            whiteSpace: 'nowrap',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
            zIndex: 1000,
          }}
        >
          {tooltipData}
        </div>
      )}

      <div
        style={{
          height: 'auto',
          width: '100%',
          position: 'relative',
          pointerEvents: loading ? 'none' : 'auto',
        }}
        onMouseMove={(event) => {
          if (loading) return;
          const boundingRect = event.currentTarget.getBoundingClientRect();
          setTooltipPosition({
            x: event.clientX - boundingRect.left,
            y: event.clientY - boundingRect.top,
          });
        }}
      >
        <ComposableMap
          projection='geoMercator'
          width={1000}
          height={500}
          style={{
            filter: loading ? 'grayscale(100%)' : 'none',
            cursor: 'pointer',
          }}
        >
          <ZoomableGroup center={center} zoom={zoomLevel}>
            <Geographies geography={GeoJSON}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const isHighlighted = highlightedCountries.includes(
                    geo.properties.name
                  ); //consider id as well
                  return (
                    <g
                      key={geo.rsmKey}
                      onMouseEnter={() =>
                        !loading && setTooltipData(geo.properties.name)
                      }
                      onMouseLeave={() => setTooltipData(null)}
                      onClick={() => onCountryClick(geo.properties.name)} // Added country click
                    >
                      <Geography
                        geography={geo}
                        style={{
                          default: {
                            fill: isHighlighted ? '#69b3a2' : '#E0E0E0',
                            outline: 'none',
                            stroke: 'gray',
                            strokeWidth: 0.4,
                          },
                          hover: {
                            fill: isHighlighted ? '#a3d9c9' : '#D6D6D6',
                            outline: 'none',
                            stroke: '#000000',
                            strokeWidth: 0.7,
                          },
                          pressed: {
                            fill: '#E42',
                            outline: 'none',
                            stroke: '#000000',
                            strokeWidth: 0.7,
                          },
                        }}
                      />
                    </g>
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
      </div>
    </Card>
  );
};

export default ChoroplethMap;
