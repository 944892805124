import React, { useEffect, useState, useCallback } from 'react';
import { notification, List, Button, Spin, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { theme } from 'antd';
import ChoroplethMap from '../../components/ChoroplethMap';
import {
  getCostsPerJurisdiction,
  getFileTypeCounts,
  getJurisdictionCounts,
  getCountryCoverage,
  getPublicationCounts,
  getTopCompanies,
  getTopInventors,
} from '../../services/insights/insights.service';
import { getIpDataByCountryCoverage } from '../../services/iPropertyService/iProperty.service';
import BarChart from '../../components/BarChart';
import PieChart from '../../components/PieChart';

const Insights: React.FC = () => {
  const { token } = theme.useToken();
  const isDarkMode =
    useSelector((state: RootState) => state.global.uitheme) === 'dark';
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null); // New state
  const [ipList, setIpList] = useState<any[]>([]); // New state
  const [loadingIpList, setLoadingIpList] = useState(false); // New loading state
  const [drawerVisible, setDrawerVisible] = useState(false); // New state
  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    width: '100%',
    backgroundColor: isDarkMode ? '#0F0F1A' : '#FCFCFC',
    padding: '40px 36px',
    marginBottom: '61px',
    borderRadius: '10px',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    maxWidth: drawerVisible ? '80%' : '100%',
    boxSizing: 'border-box',
    justifyContent: 'space-around',
    rowGap: '40px',
    columnGap: '80px',
  };

  const panelStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    right: 0,
    height: '100%',
    width: '400px',
    backgroundColor: isDarkMode ? '#1C1C1C' : '#fff',
    boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.1)',
    padding: '16px',
    zIndex: 1000,
    transform: 'translateX(100%)',
    transition: 'transform 0.3s ease',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  };

  const panelVisibleStyle: React.CSSProperties = {
    transform: 'translateX(0)', // Panel slides in
  };
  const dispatch = useDispatch<AppDispatch>();
  const [countryCoverage, setCountryCoverage] = useState<any[]>([]);
  const [jurisdictionCounts, setJurisdictionCounts] = useState<any[]>([]);
  const [fileTypes, setFileTypes] = useState<any[]>([]);
  const [publications, setPublications] = useState<any[]>([]);
  const [topCompanies, setTopCompanies] = useState<any[]>([]);
  const [topInventors, setTopInventors] = useState<any[]>([]);
  const [costsPerJurisdiction, setCostsPerJurisdiction] = useState<any[]>([]);
  const [loadingCountryCoverage, setLoadingCountryCoverage] = useState(true);
  const [loadingJurisdictionCounts, setLoadingJurisdictionCounts] =
    useState(true);
  const [loadingFileTypes, setLoadingFileTypes] = useState(true);
  const [loadingPublications, setLoadingPublications] = useState(true);
  const [loadingTopCompanies, setLoadingTopCompanies] = useState(true);
  const [loadingTopInventors, setLoadingTopInventors] = useState(true);
  const [loadingCostsPerJurisdiction, setLoadingCostsPerJurisdiction] =
    useState(true);

  const { searchTerm } =
    useSelector((state: RootState) => state.iProperty) || {};
  const selectedCompany = useSelector(
    (state: RootState) => state.global.selectedCompany
  );

  const handleCountryClick = async (country: string) => {
    setSelectedCountry(country);
    setDrawerVisible(true);
    setLoadingIpList(true);

    const queryParams = new URLSearchParams();

    if (searchTerm) {
      queryParams.append('search', searchTerm);
    }

    queryParams.append('countryCoverage', country);

    const queryString = queryParams.toString();

    try {
      const response = await getIpDataByCountryCoverage(queryString);
      setIpList(response);
    } catch (error: any) {
      notification.error({
        message: 'Failed to load IP data',
        description: error.message,
      });
    } finally {
      setLoadingIpList(false);
    }
  };

  const handleReset = () => {
    setSelectedCountry(null);
    setIpList([]);
    setDrawerVisible(false);
  };

  const sortPublications = (data: any[]) => {
    return data.sort((a, b) => {
      const yearA = a.groupName ? parseInt(a.groupName) : 0;
      const yearB = b.groupName ? parseInt(b.groupName) : 0;
      return yearA - yearB;
    });
  };

  const fetchData = useCallback(async () => {
    setLoadingCountryCoverage(true);
    setLoadingJurisdictionCounts(true);
    setLoadingFileTypes(true);
    setLoadingPublications(true);
    setLoadingTopCompanies(true);
    setLoadingTopInventors(true);
    setLoadingCostsPerJurisdiction(true);

    const queryParams = new URLSearchParams();
    if (searchTerm) {
      queryParams.append('search', searchTerm);
    }
    const queryString = queryParams.toString();

    // Fetch and update each widget individually
    try {
      const fetchCountryCoverage = async () => {
        const fetchedCountryCoverage = await getCountryCoverage(queryString);
        setCountryCoverage(fetchedCountryCoverage);
      };

      const fetchJurisdictionCounts = async () => {
        const fetchedJurisdictionCounts = await getJurisdictionCounts(
          queryString
        );
        setJurisdictionCounts(fetchedJurisdictionCounts);
      };

      const fetchFileTypes = async () => {
        const fetchedFileTypes = await getFileTypeCounts(queryString);
        setFileTypes(fetchedFileTypes);
      };

      const fetchPublications = async () => {
        const fetchedPublications = await getPublicationCounts(queryString);
        const sortedPublications = sortPublications(fetchedPublications);
        setPublications(sortedPublications);
      };

      const fetchTopCompanies = async () => {
        const fetchedTopCompanies = await getTopCompanies(queryString);
        setTopCompanies(fetchedTopCompanies);
      };

      const fetchTopInventors = async () => {
        const fetchedTopInventors = await getTopInventors(queryString);
        setTopInventors(fetchedTopInventors);
      };

      const fetchCostsPerJurisdiction = async () => {
        const fetchedCostsPerJurisdiction = await getCostsPerJurisdiction(
          queryString
        );
        setCostsPerJurisdiction(fetchedCostsPerJurisdiction);
      };

      // Fetch each widget independently
      await Promise.allSettled([
        fetchCountryCoverage().finally(() => setLoadingCountryCoverage(false)),
        fetchJurisdictionCounts().finally(() =>
          setLoadingJurisdictionCounts(false)
        ),
        fetchFileTypes().finally(() => setLoadingFileTypes(false)),
        fetchPublications().finally(() => setLoadingPublications(false)),
        fetchTopCompanies().finally(() => setLoadingTopCompanies(false)),
        fetchTopInventors().finally(() => setLoadingTopInventors(false)),
        fetchCostsPerJurisdiction().finally(() =>
          setLoadingCostsPerJurisdiction(false)
        ),
      ]);
    } catch (error: any) {
      notification.error({
        message: 'Request failed',
        description:
          error.response?.data?.message || error.message || error.toString(),
      });
    }
  }, [searchTerm]);

  useEffect(() => {
    fetchData();
  }, [fetchData, selectedCompany]);

  return (
    <div style={contentStyle}>
      <ChoroplethMap
        data={countryCoverage}
        loading={loadingCountryCoverage}
        onCountryClick={handleCountryClick}
      />
      <BarChart
        title='Jurisdiction'
        data={jurisdictionCounts}
        isApplications={false}
        isVertical={true}
        iscomp={false}
        isInv={false}
        loading={loadingJurisdictionCounts}
      />
      <BarChart
        title='Applications over time'
        data={publications}
        isApplications={true}
        isVertical={true}
        iscomp={false}
        isInv={false}
        loading={loadingPublications}
      />
      <PieChart
        data={fileTypes}
        isCosts={false}
        title='IP assets'
        loading={loadingFileTypes}
      />
      {/* <PieChart
        data={costsPerJurisdiction}
        isCosts={true}
        title='Costs per jurisdiction'
        loading={loadingCostsPerJurisdiction}
      /> */}
      <BarChart
        title='IP assets per company'
        data={topCompanies}
        isApplications={false}
        isVertical={false}
        iscomp={true}
        isInv={false}
        loading={loadingTopCompanies}
      />
      <BarChart
        title='IP assets per inventor'
        data={topInventors}
        isApplications={false}
        isVertical={false}
        iscomp={false}
        isInv={true}
        loading={loadingTopInventors}
      />
      <div
        style={{
          ...panelStyle,
          ...(drawerVisible ? panelVisibleStyle : {}),
        }}
      >
        {/* Title and Reset Button */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title
            level={4}
            style={{ margin: '0', paddingBottom: '30px' }}
          >
            {`IP List for ${selectedCountry || 'Select a Country'}`}
          </Typography.Title>
          <Button
            onClick={handleReset}
            type='primary'
            size='small'
            disabled={loadingIpList}
            style={{
              borderRadius: '29px',
              fontSize: '16px',
              padding: '10px 17px',
              backgroundColor: 'rgb(108, 114, 157)',
            }}
          >
            Reset
          </Button>
        </div>
        {loadingIpList ? (
          <Spin size='large' style={{ margin: 'auto' }} />
        ) : (
          <List
            dataSource={ipList}
            renderItem={(item) => (
              <List.Item
                key={item.guid}
                onClick={() => {
                  localStorage.setItem(
                    `selectedPropertyData_${item.guid}`,
                    JSON.stringify(item)
                  );
                  window.open(`/ip/${item.guid}`, '_blank');
                }}
                style={{ cursor: 'pointer' }}
              >
                <List.Item.Meta
                  title={item.detail['bibliographic-information'].title}
                  description={`IP type: ${item.detail['bibliographic-information'].fileType}, Jurisdiction: ${item.detail['bibliographic-information'].jurisdiction}, Application No: ${item.detail['bibliographic-information']['Application-No']}`}
                />
              </List.Item>
            )}
          />
        )}
      </div>
    </div>
  );
};

export default Insights;
