import { environment } from '../../environments/environment';
import { fetchWithCompanyId } from '../../utils/fetchWithCompanyId';
import authHeader from '../authenticationService/auth-header';

export const getBatchCostsData = async () => {
  try {
    return await fetchWithCompanyId('/cost', {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching cost data: ' + error.message);
  }
};

export const fetchCostsPerIp = async (guid: any) => {
  try {
    const response = await fetch(`${environment.apiUrl}/cost/per-ip/${guid}`, {
      headers: authHeader(),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching image data: ' + error.message);
  }
};
