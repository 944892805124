import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Card,
  ConfigProvider,
  Modal,
  Tag,
  Timeline,
  notification,
  Spin,
  Drawer,
  List,
  Select,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/configureStore';
import { Image } from 'antd';
import {
  LeftOutlined,
  RightOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  EditOutlined,
  TagOutlined,
  GroupOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as SummaryLightIcon } from '../../assets/icons/summaryLight.svg';
import { ReactComponent as SummaryDarkIcon } from '../../assets/icons/summaryDark.svg';
import { ReactComponent as NoDataLight } from '../../assets/noDataFile.svg';
import { ReactComponent as NoDataDark } from '../../assets/noDataFileDark.svg';
import { setSelectedPropertyData } from '../../store/ducks/IProperty/operations';
import { setPageInfo } from '../../store/ducks/Global/operations';
import {
  createBusinessTag,
  createNewTagGroup,
  detailsImageUrlGeneration,
  getAllBusinessTags,
  getAllTagGroups,
  getCardDetailsData,
  updateBusinessTags,
} from '../../services/iPropertyService/iProperty.service';
import bigImg from '../../assets/bigImg.jpg';
import { downloadFile } from '../../services/filesService/filesService.service';

interface StyledCarddProps {
  isDarkMode: boolean;
}

interface ImageData {
  filename: string;
  title: string;
  design: number;
  view: number;
  imageURL?: string;
  thumbnailURL?: string;
}

type GroupedImages = ImageData[][];

interface TagType {
  name: string;
  guid: string;
  isNew?: boolean;
}

const ParentDiv = styled.div`
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const StyledTimeline = styled(Timeline)<StyledCarddProps>`
  .ant-timeline-item-content {
    color: ${({ isDarkMode }) => (isDarkMode ? '#FFFFFF' : '#6C729D')};
    font-size: 18px;
    margin-top: -8px;
  }
`;

const StyledCardd = styled(Card)<StyledCarddProps>`
  height: auto;
  width: 100%;
  border: none;
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#0f0f1a' : 'white')};
  border-radius: 10px;
  .ant-card-head {
    padding: 0;
    border: none;
  }
  .ant-card-head-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .button-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-right: 20px;
    }
  }
`;

const StyledTag = styled(Tag)<StyledCarddProps>`
  width: 119px;
  height: 35px;
  font-size: 18px;
  font-weight: 450;
  text-align: center;
  color: #0f0f1a !important;
  align-content: center;
  border-radius: 0px 15px 15px 0px;
  margin: 0;
  padding: 0;
`;

const StyledList = styled.ul`
  font-size: 18px;
  color: #656a92;
  margin-top: 20px;
  li:not(:last-child) {
    margin-bottom: 15px;
  }
`;

const Key = styled.span`
  float: left;
  font-size: 18px;
`;

const Value = styled.span`
  float: right;
  font-size: 18px;
`;

interface DetailsData {
  [key: string]: any;
}

interface TagGroupType {
  id: string; // Unique ID of the tag group
  name: string; // Display name of the tag group
  guid: string; // Unique GUID (used in API calls)
  createdOn: number; // Timestamp when the group was created
  lastUpdatedOn: number; // Timestamp when the group was last updated
}

const PropertyDetailedView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { guidFromUrl } = useParams<{ guidFromUrl: string }>();
  const [activeTab, setActiveTab] = useState('details');
  const dispatch = useDispatch<AppDispatch>();
  const [currentPreview, setCurrentPreview] = useState(0);
  const navigate = useNavigate();
  const { selectedPropertyData } = useSelector(
    (state: RootState) => state.iProperty
  );

  const [detailsData, setDetailsData] = useState<DetailsData | null>(null);
  const [financialToDisplay, setFinancialToDisplay] = useState<any[]>([]);
  const [filesToDisplay, setFilesToDisplay] = useState<any[]>([]);

  const [imageGroups, setImageGroups] = useState<GroupedImages>([]);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const { uitheme } = useSelector((state: RootState) => state.global);
  const isDarkMode = uitheme === 'dark';

  const [loading, setLoading] = useState(true);
  const [isFilesloading, setFilesLoading] = useState(true);
  const [isImgLoading, setIsImgLoading] = useState(true);

  const [businessLabels, setBusinessLabels] = useState<TagType[]>([]);
  const [allBusinessLabels, setAllBusinessLabels] = useState<TagType[]>([]);
  const [draftBusinessLabels, setDraftBusinessLabels] = useState<TagType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [showBusinessLabelsPanel, setShowBusinessLabelsPanel] = useState(false);

  // const [newTags, setNewTags] = useState<TagType[]>([]); // Newly added tags by user
  const [searchGroupValue, setSearchGroupValue] = useState('');
  const [tagGroups, setTagGroups] = useState<TagGroupType[]>([]); // Available groups
  const [selectedTagGroup, setSelectedTagGroup] = useState<string | null>(null); // Group for new tags
  const [isFetchingGroups, setIsFetchingGroups] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);

  // ✅ 1. Compute assigned tags for UI (Only `detailsData.userTags`)
  const assignedTags = useMemo(() => {
    return detailsData?.userTags ? [...detailsData.userTags] : [];
  }, [detailsData?.userTags]);

  // ✅ 2. Compute available tags (Exclude already assigned tags)
  const availableTags = useMemo(() => {
    const tagMap = new Map<string, TagType>();

    // Add only business tags that are NOT in user tags
    allBusinessLabels.forEach((tag) => {
      const key = tag.name.toLowerCase();
      if (
        !detailsData?.userTags?.some(
          (userTag: { name: string }) => userTag.name.toLowerCase() === key
        )
      ) {
        tagMap.set(key, { name: tag.name, guid: tag.guid });
      }
    });

    return Array.from(tagMap.values());
  }, [detailsData?.userTags, allBusinessLabels]);

  // ✅ 3. Only set user-assigned tags in businessLabels (UI)
  useEffect(() => {
    setBusinessLabels(assignedTags);
    setDraftBusinessLabels(assignedTags);
  }, [assignedTags]);

  useEffect(() => {
    const newTagsDetected = draftBusinessLabels.filter(
      (tag) =>
        !allBusinessLabels.some(
          (t) => t.name.toLowerCase() === tag.name.toLowerCase()
        )
    );

    // setNewTags(newTagsDetected);
  }, [draftBusinessLabels, allBusinessLabels]);

  const newTags = useMemo(() => {
    return draftBusinessLabels.filter(
      (tag) =>
        !availableTags.some(
          (existingTag) =>
            existingTag.name.toLowerCase() === tag.name.toLowerCase()
        ) &&
        !businessLabels.some(
          (assignedTag) =>
            assignedTag.name.toLowerCase() === tag.name.toLowerCase()
        ) &&
        !tag.guid // Ensure it's not an already assigned tag
    );
  }, [draftBusinessLabels, availableTags, businessLabels]);

  const uniqueTagGroups = useMemo(() => {
    const groupMap = new Map<string, TagGroupType>();

    tagGroups.forEach((group) => {
      const key = group.name.toLowerCase();
      if (!groupMap.has(key)) {
        groupMap.set(key, group);
      }
    });

    return Array.from(groupMap.values());
  }, [tagGroups]);

  const isNewGroup = (groupName: string) =>
    groupName.trim() !== '' &&
    !uniqueTagGroups.some(
      (group) => group.name.toLowerCase() === groupName.toLowerCase()
    );

  useEffect(() => {
    if (newTags.length > 0 && tagGroups.length === 0) {
      setIsFetchingGroups(true);
      getAllTagGroups()
        .then((groups) => setTagGroups(groups))
        .catch((error) => {
          notification.error({
            message: 'Failed to fetch tag groups',
            description: error.message,
          });
        })
        .finally(() => setIsFetchingGroups(false));
    }
  }, [newTags]);

  const handleSaveBusinessTags = async () => {
    const propertyGuid = selectedPropertyData?.ipMetadataGuid;
    if (!propertyGuid) return;

    if (newTags.length > 0 && !selectedTagGroup) {
      notification.error({
        message: 'Please select a group for the new tags',
      });
      return;
    }

    let finalGroupGuid = selectedTagGroup;

    // ✅ If a new group is needed, create it first
    if (
      selectedTagGroup &&
      !tagGroups.some((group) => group.guid === selectedTagGroup)
    ) {
      try {
        const newGroup = await createNewTagGroup(selectedTagGroup);
        finalGroupGuid = newGroup.guid;
        setTagGroups([...tagGroups, newGroup]);
        setSelectedTagGroup(null);
      } catch (error: any) {
        notification.error({
          message: 'Failed to create new group',
          description: error.message,
        });
        return;
      }
    }

    let finalTags = [...businessLabels]; // Start with all previously assigned tags

    // ✅ If new tags exist, create them before saving
    let createdTagsArr: TagType[] = [];
    if (newTags.length > 0) {
      try {
        createdTagsArr = await Promise.all(
          newTags.map((tag) =>
            createBusinessTag({
              name: tag.name,
              tagGroupGuid: finalGroupGuid!,
            })
          )
        );

        finalTags = [
          ...finalTags, // Keep all previously assigned tags
          ...createdTagsArr.map((ct: any) => ({
            name: ct.name,
            guid: ct.guid,
          })), // Add newly created tags
        ];
      } catch (error: any) {
        notification.error({
          message: 'Failed to create new business tags',
          description: error.message,
        });
        return;
      }
    }

    // ✅ Immediately update `availableTags` to prevent re-highlighting on reopen
    setAllBusinessLabels((prevTags) => [
      ...prevTags,
      ...createdTagsArr.map((ct) => ({
        name: ct.name,
        guid: ct.guid,
      })),
    ]);

    // ✅ Add existing tags selected from the dropdown
    draftBusinessLabels.forEach((selectedTag) => {
      if (
        availableTags.some(
          (existingTag) =>
            existingTag.name.toLowerCase() === selectedTag.name.toLowerCase()
        ) &&
        !finalTags.some(
          (finalTag) =>
            finalTag.name.toLowerCase() === selectedTag.name.toLowerCase()
        )
      ) {
        finalTags.push(selectedTag);
      }
    });

    // ✅ Identify removed tags (tags that were assigned before but are no longer in `draftBusinessLabels`)
    const removedTags = businessLabels.filter(
      (tag) =>
        !draftBusinessLabels.some(
          (selectedTag) =>
            selectedTag.name.toLowerCase() === tag.name.toLowerCase()
        )
    );

    // ✅ Remove deleted tags from final list
    finalTags = finalTags.filter(
      (tag) => !removedTags.some((removedTag) => removedTag.guid === tag.guid)
    );

    // ✅ Ensure only valid GUIDs are sent
    const tagGuids = finalTags
      .filter((tag) => tag.guid) // Remove empty entries
      .map((tag) => tag.guid);

    try {
      await updateBusinessTags(tagGuids, propertyGuid);
      notification.success({ message: 'Business tags updated successfully' });

      // ✅ Reset selectedTagGroup after successful update
      setSelectedTagGroup(null);

      // ✅ Refresh UI with updated tags
      const updatedDetails = await getCardDetailsData(propertyGuid);
      setDetailsData(updatedDetails);
      if (updatedDetails?.userTags) {
        const normalized = updatedDetails.userTags.map((tag: any) => ({
          name: String(tag.name),
          guid: tag.guid,
        }));
        setBusinessLabels(normalized);
      }

      // ✅ Ensure newly created tags don't get highlighted again
      setSearchValue('');
      setShowBusinessLabelsPanel(false);
    } catch (error: any) {
      notification.error({
        message: 'Failed to update business tags',
        description: error.message,
      });
    }
  };

  // useEffect(() => {
  //   if (selectedPropertyData) {
  //     dispatch(setSelectedPropertyData(selectedPropertyData));
  //   }
  // }, [dispatch, selectedPropertyData]);

  useEffect(() => {
    setLoading(true);

    const fetchDetailsAndTags = async (metadataId?: string) => {
      const idToFetch =
        metadataId || selectedPropertyData?.ipMetadataGuid || guidFromUrl;
      console.log('>>>', idToFetch);
      if (!idToFetch) return;
      const storageKey = `selectedPropertyData_${idToFetch}`;
      const storedData = localStorage.getItem(storageKey);
      console.log('1.1', storedData);

      if (storedData) {
        const storedJson = JSON.parse(storedData);
        const storedDataGuid = storedJson?.ipMetadataGuid || storedJson?.guid;
        console.log('1.2', storedDataGuid);

        try {
          // Fetch details
          const detailsData = await getCardDetailsData(storedDataGuid);
          setDetailsData(detailsData);
          setFilesToDisplay(detailsData?.files || []);
          setFinancialToDisplay(detailsData?.financial || []);

          // Fetch all business tags
          const allTags = await getAllBusinessTags();

          // Remove duplicate tag names and update available tags
          const uniqueTagsMap = new Map<string, TagType>();
          allTags.forEach((tag: any) => {
            const key = tag.name.toLowerCase();
            if (!uniqueTagsMap.has(key)) {
              uniqueTagsMap.set(key, { name: tag.name, guid: tag.guid });
            }
          });

          setAllBusinessLabels(Array.from(uniqueTagsMap.values()));

          setSearchValue('');
        } catch (error: any) {
          notification.error({
            message: 'Failed to fetch data',
            description: error.message,
          });
        } finally {
          setFilesLoading(false);
          setLoading(false);
        }
      }
    };

    if (!selectedPropertyData) {
      const savedData = localStorage.getItem('selectedPropertyData');
      if (savedData) {
        const parsedData = JSON.parse(savedData);
        // dispatch(setSelectedPropertyData(parsedData));
        fetchDetailsAndTags(id);
      } else if (id) {
        fetchDetailsAndTags(id);
      }
    } else {
      fetchDetailsAndTags();
    }
  }, [dispatch, selectedPropertyData, id]);

  const [imageURL, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!detailsData?.images) {
      setIsImgLoading(false);
      return;
    }
    const fetchImageGroups = async () => {
      setIsImgLoading(true);
      const groups: GroupedImages = [];
      const BATCH_SIZE = 3;
      for (let i = 0; i < detailsData.images.length; i += BATCH_SIZE) {
        const batch = detailsData.images.slice(i, i + BATCH_SIZE);
        const batchResults = await Promise.all(
          batch.map(async (group: any) => {
            return Promise.all(
              group.map(async (image: any) => {
                try {
                  const response = await detailsImageUrlGeneration(
                    image.filename
                  );
                  return {
                    filename: image.filename,
                    imageURL: response.imageURL,
                    thumbnailURL: response.thumbnailURL,
                    title: image['image-title'],
                    design: image.design,
                    view: image.view,
                  };
                } catch (error) {
                  notification.error({
                    message: 'Image Fetch Failed',
                    description: `Could not fetch image URL for ${image['image-title']}.`,
                  });
                  return {
                    filename: image.filename,
                    imageURL: '',
                    thumbnailURL: '',
                    title: image['image-title'],
                    design: image.design,
                    view: image.view,
                  };
                }
              })
            );
          })
        );
        groups.push(...batchResults);
      }
      setImageGroups(groups);
      setIsImgLoading(false);
    };
    fetchImageGroups();
  }, [detailsData]);

  useEffect(() => {
    dispatch(
      setPageInfo({
        currentPageName: 'IP management',
      })
    );
  }, [dispatch]);

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const flatImages = imageGroups.flat();

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleThumbnailPrevClick = () => {
    setCurrentGroupIndex((prevIndex) =>
      prevIndex === 0 ? imageGroups.length - 1 : prevIndex - 1
    );
    setCurrentPreview(0);
  };

  const handleThumbnailNextClick = () => {
    setCurrentGroupIndex((prevIndex) =>
      prevIndex === imageGroups.length - 1 ? 0 : prevIndex + 1
    );
    setCurrentPreview(0);
  };

  const handleImageClick = (index: number) => {
    setCurrentImageIndex(index);
    setIsModalVisible(true);
  };

  const handleNextClick = () => {
    if (currentImageIndex < imageGroups[currentGroupIndex].length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    } else if (currentGroupIndex < imageGroups.length - 1) {
      setCurrentGroupIndex(currentGroupIndex + 1);
      setCurrentImageIndex(0);
    }
  };

  const handlePrevClick = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    } else if (currentGroupIndex > 0) {
      setCurrentGroupIndex(currentGroupIndex - 1);
      setCurrentImageIndex(imageGroups[currentGroupIndex - 1].length - 1);
    }
  };

  const handleNextGroupClick = () => {
    if (currentGroupIndex < imageGroups.length - 1) {
      setCurrentGroupIndex(currentGroupIndex + 1);
      setCurrentImageIndex(0);
    }
  };

  const handlePrevGroupClick = () => {
    if (currentGroupIndex > 0) {
      setCurrentGroupIndex(currentGroupIndex - 1);
      setCurrentImageIndex(0);
    }
  };

  const currentGroup = imageGroups[currentGroupIndex] || [];

  const images = [
    {
      src: bigImg,
      alt: 'Image 1',
    },
  ];

  const formatKey = (key: string) => {
    key = key.startsWith('@') ? key.slice(1) : key;
    if (key.includes('-')) {
      return key.replace(/-/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());
    } else {
      return key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
    }
  };

  const renderSection = (
    key: any,
    value: any,
    margin?: any,
    newP?: boolean
  ) => {
    return (
      <div key={key} style={{ marginBottom: margin || '20px' }}>
        {key && (
          <div style={{ fontSize: '18px', color: '#656A92' }}>
            {formatKey(key)}
          </div>
        )}
        <div style={{ fontSize: '18px', color: '#A3A7C3', fontWeight: 500 }}>
          {Array.isArray(value)
            ? newP
              ? value.map((item, index) => (
                  <div key={index}>
                    {item}
                    {index !== value.length - 1 && <br />}
                  </div>
                ))
              : value.join(', ')
            : value}
        </div>
      </div>
    );
  };

  const renderTitle = (key: any) => (
    <div
      style={{
        color: isDarkMode ? 'white' : '#6C729D',
        borderBottom: '1px solid #656A92',
        paddingBottom: '15px',
        marginBottom: '15px',
        fontSize: '22px',
        fontWeight: 550,
      }}
    >
      {formatKey(key)}
    </div>
  );

  const renderDesignations = (designations: any[]) => (
    <StyledList style={{ paddingLeft: '15px' }}>
      {designations.map((designation, index) => (
        <li key={index}>
          {designation['@country']} {designation['@status']} (
          {designation['@date']})
        </li>
      ))}
    </StyledList>
  );

  const handleFileDownload = async (filename: string) => {
    setFilesLoading(true);
    try {
      const response = await downloadFile(filename);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      notification.error({
        message: 'File Download Failed',
        description: `Could not download file ${filename}. Please try again later.`,
      });
    } finally {
      setFilesLoading(false);
    }
  };

  return (
    <StyledCardd
      isDarkMode={isDarkMode}
      bodyStyle={{ padding: '20px 60px' }}
      size='small'
      style={{
        width: '100%',
        minHeight: '1430px',
        borderRadius: '10px',
        backgroundColor: uitheme === 'dark' ? '#0F0F1A' : '#FCFCFC',
      }}
    >
      {loading ? (
        <Spin
          tip='Loading...'
          style={{
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div
            key='details'
            style={{
              display: 'flex',
              flexDirection: 'column',
              color: 'white',
              width: '55%',
            }}
          >
            <span
              style={{
                fontSize: '24px',
                fontWeight: 700,
                color: isDarkMode ? 'white' : '#6C729D',
                marginBottom: '30px',
              }}
            >
              {selectedPropertyData?.card.title ||
                detailsData?.['bibliographic-information'].title}
            </span>
            {detailsData?.abstract &&
              renderSection('Abstract', detailsData.abstract)}
            {detailsData?.['bibliographic-information'] && (
              <div style={{ marginBottom: '20px' }}>
                {Object.entries(detailsData['bibliographic-information']).map(
                  ([subKey, subValue]) => {
                    if (subKey.toLowerCase() === 'title') return null;
                    return renderSection(subKey, subValue);
                  }
                )}
                {detailsData?.reference &&
                  renderSection('Reference', detailsData.reference)}
              </div>
            )}
            {detailsData?.images?.length && (
              <div style={{ marginBottom: '35px' }}>
                <div
                  style={{
                    color: isDarkMode ? 'white' : '#6C729D',
                    borderBottom: '1px solid #656A92',
                    paddingBottom: '15px',
                    fontSize: '22px',
                    fontWeight: 550,
                  }}
                >
                  Images
                </div>
                {isImgLoading ? (
                  <Spin
                    tip='Loading...'
                    style={{
                      margin: '20px auto',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      gap: '30px',
                      paddingTop: '20px',
                      alignItems: 'center',
                    }}
                  >
                    <DoubleLeftOutlined
                      onClick={handlePrevGroupClick}
                      style={{
                        fontSize: '24px',
                        color: '#A3A7C3',
                        cursor:
                          currentGroupIndex > 0 ? 'pointer' : 'not-allowed',
                      }}
                      disabled={isImgLoading || currentGroupIndex <= 0}
                    />
                    <LeftOutlined
                      onClick={handlePrevClick}
                      style={{
                        fontSize: '24px',
                        color: '#A3A7C3',
                        cursor:
                          currentGroupIndex > 0 || currentImageIndex > 0
                            ? 'pointer'
                            : 'not-allowed',
                      }}
                      disabled={
                        isImgLoading ||
                        (currentGroupIndex <= 0 && currentImageIndex <= 0)
                      }
                    />
                    <List
                      dataSource={imageGroups[currentGroupIndex] || []}
                      renderItem={(item, index) => (
                        <List.Item
                          style={{ display: 'inline-block', marginRight: 16 }}
                        >
                          <Image
                            src={item.thumbnailURL}
                            alt={item.title}
                            style={{
                              width: 160,
                              height: 104,
                              objectFit: 'cover',
                              cursor: 'pointer',
                              borderRadius: '10px',
                              border:
                                index === currentImageIndex
                                  ? '2px solid #A3A7C3'
                                  : 'none',
                            }}
                            preview={false}
                            onClick={() => handleImageClick(index)}
                          />
                        </List.Item>
                      )}
                    />
                    <RightOutlined
                      onClick={handleNextClick}
                      style={{
                        fontSize: '24px',
                        color: '#A3A7C3',
                        cursor:
                          currentGroupIndex < imageGroups.length - 1 ||
                          currentImageIndex <
                            imageGroups[currentGroupIndex]?.length - 1
                            ? 'pointer'
                            : 'not-allowed',
                      }}
                      disabled={
                        isImgLoading ||
                        (currentGroupIndex >= imageGroups.length - 1 &&
                          currentImageIndex >=
                            imageGroups[currentGroupIndex]?.length - 1)
                      }
                    />
                    <DoubleRightOutlined
                      onClick={handleNextGroupClick}
                      style={{
                        fontSize: '24px',
                        color: '#A3A7C3',
                        cursor:
                          currentGroupIndex < imageGroups.length - 1
                            ? 'pointer'
                            : 'not-allowed',
                      }}
                      disabled={
                        isImgLoading ||
                        currentGroupIndex >= imageGroups.length - 1
                      }
                    />
                  </div>
                )}
                {isModalVisible && (
                  <Modal
                    visible={isModalVisible}
                    onCancel={closeModal}
                    footer={null}
                    destroyOnClose
                    width={800}
                    centered
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <LeftOutlined
                        onClick={handlePrevClick}
                        style={{
                          fontSize: '24px',
                          color: '#1890ff',
                          cursor: 'pointer',
                        }}
                      />
                      <Image
                        src={
                          imageGroups[currentGroupIndex][currentImageIndex]
                            ?.imageURL
                        }
                        alt={
                          imageGroups[currentGroupIndex][currentImageIndex]
                            ?.title
                        }
                        preview={false}
                      />
                      <RightOutlined
                        onClick={handleNextClick}
                        style={{
                          fontSize: '24px',
                          color: '#1890ff',
                          cursor: 'pointer',
                        }}
                      />
                    </div>
                  </Modal>
                )}
              </div>
            )}
            {detailsData &&
              Object.entries(detailsData).map(([key, value]) => {
                if (
                  key === 'bibliographic-information' ||
                  key === 'abstract' ||
                  key === 'summary' ||
                  key === 'images' ||
                  key === 'business' ||
                  key === 'financial' ||
                  key === 'events' ||
                  key === 'reference' ||
                  key === 'files' ||
                  key === 'userTags'
                ) {
                  return null;
                }
                if (key === '@related-parties') {
                  return Object.entries(value as Record<string, any>).map(
                    ([subKey, subValue]) => renderSection(subKey, subValue, 30)
                  );
                }
                if (key === 'designations' && Array.isArray(value)) {
                  return (
                    <div key={key} style={{ marginBottom: '25px' }}>
                      {renderTitle(key)}
                      <div>{renderDesignations(value)}</div>
                    </div>
                  );
                }
                return (
                  <div key={key} style={{ marginBottom: '25px' }}>
                    {renderTitle(key)}
                    <div>
                      {typeof value === 'object' && !Array.isArray(value)
                        ? Object.entries(value as Record<string, any>).map(
                            ([subKey, subValue]) =>
                              renderSection(subKey, subValue)
                          )
                        : Array.isArray(value) &&
                          value.some((item) => typeof item === 'object')
                        ? value.map((item, index) => (
                            <div key={index}>
                              {typeof item === 'object' && item !== null
                                ? Object.entries(
                                    item as Record<string, any>
                                  ).map(([nestedKey, nestedValue]) =>
                                    renderSection(nestedKey, nestedValue)
                                  )
                                : renderSection('', item)}
                            </div>
                          ))
                        : renderSection('', value, '', true)}
                    </div>
                  </div>
                );
              })}
            {detailsData?.events && (
              <div>
                {renderTitle('Events')}
                <div>
                  <ConfigProvider
                    theme={{
                      components: {
                        Timeline: { tailColor: '#A3A7C3', tailWidth: 2 },
                      },
                    }}
                  >
                    <StyledTimeline
                      style={{
                        height: 'auto',
                        marginTop: '30px',
                      }}
                      isDarkMode={isDarkMode}
                    >
                      {detailsData.events.map((event: any, index: any) => (
                        <StyledTimeline.Item key={index} color='#656A92'>
                          <span>{event.date}</span>
                          <span style={{ marginLeft: '35px' }}>
                            {event.description}
                          </span>
                        </StyledTimeline.Item>
                      ))}
                    </StyledTimeline>
                  </ConfigProvider>
                </div>
              </div>
            )}
          </div>
          <div
            key='additional'
            style={{
              width: '40%',
              display: 'flex',
              flexDirection: 'column',
              gap: '30px',
            }}
          >
            <div key='summary'>
              <span
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  color: isDarkMode ? '#FFFFFF' : '#656A92',
                  margin: '0px 0px 15px 30px',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                {isDarkMode ? <SummaryDarkIcon /> : <SummaryLightIcon />}
                <span style={{ marginLeft: '10px' }}>Summary</span>
              </span>
              <div
                style={{
                  backgroundColor: isDarkMode ? '#363853' : '#F5F5F5',
                  border: !isDarkMode ? '2px solid #E5E5E5' : 'none',
                  boxShadow: !isDarkMode
                    ? '0px 8px 16px 0px #00000014'
                    : 'none',
                  width: '100%',
                  height: 'fit-content',
                  padding: '30px 25px',
                  borderRadius: '13px ',
                }}
              >
                <StyledList
                  style={{
                    listStyleType: 'disc',
                    paddingLeft: '20px',
                    fontSize: '18px',
                    color: isDarkMode ? '#FFFFFF' : '#7E7E7E',
                    margin: 0,
                  }}
                >
                  {detailsData?.summary?.map(
                    (item: any, index: any) =>
                      !!item.length && <li key={index}>{item}</li>
                  )}
                </StyledList>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                height: 'fit-content',
                padding: '30px 25px',
                borderRadius: '13px ',
                display: 'flex',
                flexDirection: 'column',
                gap: '35px',
                backgroundColor: isDarkMode ? '#363853' : '#F5F5F5',
                border: !isDarkMode ? '2px solid #E5E5E5' : 'none',
                boxShadow: !isDarkMode ? '0px 8px 16px 0px #00000014' : 'none',
              }}
            >
              <div>
                <div
                  style={{
                    color: isDarkMode ? '#FFFFFF' : '#656A92',
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #A9A9A9',
                    paddingBottom: '15px',
                    fontSize: '22px',
                  }}
                >
                  <b>Business</b>
                  <Button
                    shape='round'
                    size='large'
                    style={{
                      width: '45px',
                      marginLeft: '10px',
                      color: isDarkMode ? '#CBD2D9' : '#656A92',
                      background: isDarkMode ? '#0F0F1A' : 'none',
                      border: isDarkMode ? 'none' : '1px solid #656A92',
                      fontWeight: 600,
                    }}
                    onClick={() => setShowBusinessLabelsPanel(true)}
                  >
                    <EditOutlined />
                  </Button>
                </div>
                <div
                  style={{
                    marginTop: '15px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '3px',
                    color: isDarkMode ? '#A3A7C3' : '#7E7E7E',
                    fontSize: '18px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '8px',
                      fontSize: '18px',
                    }}
                  >
                    {businessLabels && businessLabels.length > 0 ? (
                      businessLabels.map((label) => (
                        <Tag
                          key={label.guid}
                          style={{
                            fontSize: '16px',
                            padding: '4px 8px',
                            color: 'gray',
                          }}
                        >
                          {label.name}
                        </Tag>
                      ))
                    ) : (
                      <p style={{ fontWeight: 550, marginBottom: 0 }}>
                        No business data available
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{
                    color: '#656A92',
                    borderBottom: '1px solid #A9A9A9',
                    paddingBottom: '15px',
                    fontSize: '22px',
                  }}
                >
                  <b>Financial (costs)</b>
                  <Button
                    shape='round'
                    size='large'
                    style={{
                      marginLeft: '10px',
                      color: isDarkMode ? '#CBD2D9' : '#656A92',
                      background: isDarkMode ? '#0F0F1A' : 'none',
                      border: isDarkMode ? 'none' : '1px solid #656A92',
                      fontWeight: 600,
                      display: 'none',
                    }}
                    onClick={() => navigate('/financial')}
                  >
                    See all
                  </Button>
                </div>
                <div>
                  {loading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        width: '100%',
                      }}
                    >
                      <Spin size='large' />
                    </div>
                  ) : financialToDisplay?.length > 0 ? (
                    <StyledList
                      style={{
                        marginTop: '15px',
                        listStyleType: 'disc',
                        paddingLeft: '20px',
                        fontSize: '18px',
                        color: isDarkMode ? '#FFFFFF' : '#7E7E7E',
                      }}
                    >
                      {financialToDisplay
                        .sort((a, b) => b.description - a.description)
                        .map((item) => (
                          <li key={item.id}>
                            {item.description}: {item.currency}{' '}
                            {Math.abs(item.amount).toLocaleString('de-DE', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </li>
                        ))}
                    </StyledList>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        marginTop: '15px',
                      }}
                    >
                      <div
                        style={{
                          color: isDarkMode ? '#A3A7C3' : '#7E7E7E',
                          fontSize: '18px',
                          width: '75%',
                        }}
                      >
                        <p style={{ fontWeight: 550, marginBottom: 0 }}>
                          No Financial data available
                        </p>
                      </div>
                      <div>{isDarkMode ? <NoDataDark /> : <NoDataLight />}</div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div
                  style={{
                    color: '#656A92',
                    borderBottom: '1px solid #A9A9A9',
                    fontSize: '22px',
                    paddingBottom: '15px',
                  }}
                >
                  <b>Files</b>
                  {filesToDisplay.length >= 4 && (
                    <Button
                      shape='round'
                      size='large'
                      style={{
                        marginLeft: '10px',
                        color: isDarkMode ? '#CBD2D9' : '#656A92',
                        background: isDarkMode ? '#0F0F1A' : 'none',
                        border: isDarkMode ? 'none' : '1px solid #656A92',
                        fontWeight: 600,
                      }}
                      onClick={() =>
                        navigate(
                          `/files?filter=${selectedPropertyData?.ipMetadataGuid}`
                        )
                      }
                    >
                      See all
                    </Button>
                  )}
                </div>
                <div>
                  {isFilesloading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        width: '100%',
                      }}
                    >
                      <Spin size='large' />
                    </div>
                  ) : filesToDisplay && filesToDisplay.length > 0 ? (
                    <StyledList
                      style={{
                        marginTop: '15px',
                        listStyleType: 'disc',
                        paddingLeft: '20px',
                        fontSize: '18px',
                        color: isDarkMode ? '#FFFFFF' : '#7E7E7E',
                      }}
                    >
                      {filesToDisplay.map((file: any) => (
                        <li key={file.filename}>
                          <span
                            style={{
                              color: isDarkMode ? '#A3A7C3' : '#6C729D',
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                            onClick={() => handleFileDownload(file.filename)}
                          >
                            {file.description}
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: '14px',
                              color: isDarkMode ? '#A3A7C3' : '#7E7E7E',
                            }}
                          >
                            {file.date}
                          </span>
                        </li>
                      ))}
                    </StyledList>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        marginTop: '15px',
                      }}
                    >
                      <div
                        style={{
                          color: isDarkMode ? '#A3A7C3' : '#7E7E7E',
                          fontSize: '18px',
                          width: '75%',
                        }}
                      >
                        <p style={{ fontWeight: 550, marginBottom: 0 }}>
                          You have no documents in system
                        </p>
                      </div>
                      <div>{isDarkMode ? <NoDataDark /> : <NoDataLight />}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Side panel (Drawer) for editing Business Labels */}
      <Drawer
        title='Edit Business Labels'
        placement='right'
        maskClosable={false}
        onClose={() => {
          setDraftBusinessLabels(businessLabels);
          setShowBusinessLabelsPanel(false);
          setSelectedTagGroup(null);
          setSearchValue('');
          setSearchGroupValue('');
        }}
        visible={showBusinessLabelsPanel}
        width={400}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          {/* ✅ TAGS SECTION */}
          <div>
            <h3 style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <TagOutlined style={{ color: '#1890ff' }} />
              Labels
            </h3>
            <Select
              mode='tags'
              style={{ width: '100%' }}
              placeholder='Enter or select labels'
              // Control dropdown open state
              open={selectOpen}
              onDropdownVisibleChange={(open) => setSelectOpen(open)}
              value={draftBusinessLabels.map((label) => label.name)}
              onChange={(value: string[]) => {
                const updated = value.map((tagName) => {
                  const lower = tagName.toLowerCase();
                  const existing = availableTags.find(
                    (tag) => tag.name.toLowerCase() === lower
                  );
                  const draft = draftBusinessLabels.find(
                    (label) => label.name.toLowerCase() === lower
                  );
                  return existing
                    ? existing
                    : draft
                    ? draft
                    : { name: tagName, guid: '', isNew: true };
                });
                setDraftBusinessLabels(updated);
                // Close the dropdown on selection
                setSearchValue('');
              }}
              onSearch={(val) => setSearchValue(val)}
              filterOption={(input, option) =>
                (option?.value?.toString() || '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onInputKeyDown={(e) => {
                if (e.key === 'Enter' && searchValue) {
                  if (
                    !availableTags.some(
                      (tag) =>
                        tag.name.toLowerCase() === searchValue.toLowerCase()
                    ) &&
                    !draftBusinessLabels.some(
                      (label) =>
                        label.name.toLowerCase() === searchValue.toLowerCase()
                    )
                  ) {
                    e.preventDefault();
                    setDraftBusinessLabels([
                      ...draftBusinessLabels,
                      { name: searchValue, guid: '', isNew: true },
                    ]);
                    // Close the dropdown as soon as a new tag is created via input
                    setSelectOpen(false);
                  }
                  setSearchValue('');
                }
              }}
              onBlur={() => {
                // Only remove a pending (unconfirmed) tag if searchValue exists.
                if (searchValue) {
                  setDraftBusinessLabels((prev) =>
                    prev.filter(
                      (tag) =>
                        !(
                          tag.isNew &&
                          tag.name.toLowerCase() === searchValue.toLowerCase()
                        )
                    )
                  );
                  setSearchValue('');
                }
              }}
              tagRender={(props) => {
                const { label, value, closable, onClose } = props;
                const found = draftBusinessLabels.find(
                  (lbl) =>
                    (lbl.name || '').toLowerCase() ===
                    (value || '').toLowerCase()
                );
                const isNew = found?.isNew;
                return (
                  <Tag
                    color={isNew ? 'blue' : 'default'}
                    closable={closable}
                    onClose={onClose}
                    style={{ margin: '3px', fontSize: '14px' }}
                  >
                    {label} {isNew ? ' (New)' : ''}
                  </Tag>
                );
              }}
              options={[
                // If the searchValue doesn't match an existing tag, show a "(New)" option.
                ...(searchValue &&
                !availableTags.some(
                  (tag) => tag.name.toLowerCase() === searchValue.toLowerCase()
                )
                  ? [
                      {
                        value: searchValue,
                        label: (
                          <span
                            style={{
                              color: 'rgb(24, 144, 255)',
                              fontWeight: 'bold',
                            }}
                          >
                            {searchValue} (New)
                          </span>
                        ),
                      },
                    ]
                  : []),
                // List all available tags.
                ...availableTags.map((tag) => ({
                  value: tag.name,
                  label: tag.name,
                })),
              ]}
            />
          </div>

          {/* ✅ GROUP SECTION (Appears Only If New Tags Exist) */}
          {newTags.length > 0 && (
            <div style={{ marginTop: '20px' }}>
              <h3 style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <GroupOutlined style={{ color: '#52c41a' }} />
                Assign a Group to New Labels
              </h3>
              <Select
                style={{ width: '100%' }}
                placeholder='Enter or select a group'
                value={selectedTagGroup || undefined}
                // Let the user clear the current selection
                allowClear
                // Keep the search box visible so the user can type a new group name
                showSearch
                // Update your local search state whenever the user types
                onSearch={(val) => setSearchGroupValue(val)}
                // When a dropdown option is selected
                onChange={(value: any) => {
                  setSelectedTagGroup(value);
                  setSearchGroupValue(''); // Clear the search input
                }}
                // Capture the Enter key while the user is typing
                onInputKeyDown={(e) => {
                  if (e.key === 'Enter' && searchGroupValue.trim() !== '') {
                    // If the typed value isn’t already in the existing groups, commit it as a new group.
                    if (
                      !uniqueTagGroups.some(
                        (group) =>
                          group.name.toLowerCase() ===
                          searchGroupValue.toLowerCase()
                      )
                    ) {
                      e.preventDefault();
                      setSelectedTagGroup(searchGroupValue);
                    }
                    setSearchGroupValue('');
                  }
                }}
                // Clear any pending input when the user clicks away
                onBlur={() => setSearchGroupValue('')}
                // Turn off built-in filtering; we’ll handle it ourselves
                filterOption={false}
                // The items to show in the dropdown
                options={[
                  // If the user typed a new group name, show a "Create ..." item
                  ...(searchGroupValue.trim() !== '' &&
                  isNewGroup(searchGroupValue)
                    ? [
                        {
                          value: searchGroupValue,
                          label: (
                            <span
                              style={{ color: 'green', fontWeight: 'bold' }}
                            >
                              {searchGroupValue} (New)
                            </span>
                          ),
                        },
                      ]
                    : []),
                  // Filter existing groups based on the user's search
                  ...uniqueTagGroups
                    .filter((group) =>
                      group.name
                        .toLowerCase()
                        .includes(searchGroupValue.toLowerCase())
                    )
                    .map((group) => ({
                      value: group.guid,
                      label: group.name,
                    })),
                ]}
              />
            </div>
          )}

          {/* ✅ ACTION BUTTON */}
          <div
            style={{
              marginTop: 'auto',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              type='primary'
              style={{
                borderRadius: '29px',
                fontSize: '16px',
                padding: '10px 17px',
                backgroundColor: 'rgb(108, 114, 157)',
              }}
              onClick={handleSaveBusinessTags}
            >
              Save
            </Button>
          </div>
        </div>
      </Drawer>
    </StyledCardd>
  );
};

export default PropertyDetailedView;
