import React from 'react';
import { Button, Card, Dropdown, Menu, notification } from 'antd';
import { FilePdfTwoTone, FilePdfOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ReactComponent as CostCardIcon } from '../assets/icons/costCardIcon.svg';
import { ReactComponent as FilesCardIcon } from '../assets/icons/filesCardIcon.svg';
import { ReactComponent as MoreOptionsIcon } from '../assets/icons/moreOptions.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store/configureStore';
import { downloadFile } from '../services/filesService/filesService.service';
import { formatDate } from '../utils/cards';

interface DataCardProps {
  data: any;
  isDarkMode: boolean;
  isFiles?: boolean;
  cardType?: 'task' | 'default'; // New prop to switch layouts
  fileName?: string;
  onMarkAsRead?: (fileName: string) => void;
}

const StyledCard = styled(Card)`
  height: 46px;
  display: flex;
  align-items: center;
  overflow: hidden;

  .ant-card-body {
    width: -webkit-fill-available;
  }
`;

const labelStyle = {
  fontSize: '11px',
  color: '#999',
  marginBottom: '2px',
};

const valueStyle = {
  color: '#656A92',
  fontWeight: 500,
  margin: 0,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

const DataCard: React.FC<DataCardProps> = ({
  data,
  isDarkMode,
  isFiles,
  cardType = 'default',
  onMarkAsRead,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    userData: { publisherId },
  } = useSelector((state: RootState) => state.global);

  const date = isFiles || cardType === 'task' ? data.date : data.costDate;
  const fileName = isFiles ? data.filepath.split('/').pop() : data.description;
  const fileDescription = isFiles ? data.fileJSON.fileDescription : null;

  const formattedDeadline =
    cardType === 'task' && data.deadline
      ? new Intl.DateTimeFormat('nl-NL', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }).format(new Date(data.deadline))
      : null;

  const handleDownloadClick = async () => {
    try {
      const response = await downloadFile(data.filenameNew);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', fileName); //TODO change the diwnloadable file name asa fifileJSON available
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      if (onMarkAsRead) onMarkAsRead(data.guid);
    } catch (error: any) {
      notification.error({
        message: 'Download failed',
        description: error.message || 'An error occurred during download.',
      });
    }
  };

  return (
    <StyledCard
      style={{
        background: isDarkMode ? '#2B2B39' : '#F5F5F5',
        border: '1px solid #6C729D',
        display: 'flex',
        alignItems: 'center',
        width: '-webkit-fill-available',
        height: isFiles || cardType === 'task' ? '60px' : '46px',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '15px',
        }}
      >
        {/* LEFT SECTION */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexBasis: isFiles || cardType === 'task' ? '50%' : '40%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {isFiles ||
            (cardType === 'default' && (
              <div
                style={{
                  width: '24px',
                  height: '24px',
                  minWidth: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {isFiles ? (
                  <FilesCardIcon style={{ width: '100%', height: '100%' }} />
                ) : (
                  cardType === 'default' && (
                    <CostCardIcon style={{ width: '100%', height: '100%' }} />
                  )
                )}
              </div>
            ))}
          <div
            style={{
              marginLeft: '8px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <span
              style={{
                color: '#A3A7C3',
                fontWeight: 'bold',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {fileName || data.description}
            </span>
            {fileDescription && (
              <span
                style={{
                  color: '#656A92',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {fileDescription}
              </span>
            )}
          </div>
        </div>

        {/* CATEGORY SECTION with label */}
        <div
          style={{
            flexBasis: '20%',
            textAlign: 'left',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {cardType === 'task' && (
            <div style={{ flexBasis: '20%', minWidth: '120px' }}>
              <p style={{ ...labelStyle }}>Category</p>
              <p style={{ ...valueStyle }}>
                {Array.isArray(data.category) ? data.category.join(' | ') : ''}
              </p>
            </div>
          )}

          {cardType === 'default' && !isFiles && (
            <p style={{ color: '#656A92', margin: 0 }}>
              <strong>{data.invoiceNumber}</strong>
            </p>
          )}
        </div>

        {/* DATE SECTION with label */}
        <div style={{ flexBasis: '20%', textAlign: 'left' }}>
          {cardType === 'task' ? (
            <div style={{ flexBasis: '20%', minWidth: '100px' }}>
              <p style={{ ...labelStyle }}>Date</p>
              <p style={{ ...valueStyle }}>{formatDate(date)}</p>
            </div>
          ) : (
            <p style={{ color: '#656A92', margin: 0 }}>
              <strong>{formatDate(date)}</strong>
            </p>
          )}
        </div>

        {/* DEADLINE */}
        {formattedDeadline && (
          <div style={{ flexBasis: '20%', minWidth: '100px' }}>
            <p style={{ ...labelStyle }}>Deadline</p>
            <p style={{ ...valueStyle }}>{formattedDeadline}</p>
          </div>
        )}

        {/* Amount Display for Costs Only */}
        {!isFiles && cardType === 'default' && (
          <div
            style={{
              flexBasis: '15%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              textWrap: 'nowrap',
            }}
          >
            <p style={{ color: '#8589C1', margin: 0 }}>
              <strong>{data.amount.toFixed(2)}€</strong>
            </p>
          </div>
        )}

        {/* Download Button for Files */}
        {isFiles ? (
          <div style={{ flexBasis: '10%', textAlign: 'right' }}>
            <Button type='link' onClick={handleDownloadClick}>
              Download
            </Button>
          </div>
        ) : (
          cardType === 'default' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexBasis: '5%',
                alignItems: 'center',
              }}
            >
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item>No actions</Menu.Item>
                  </Menu>
                }
                trigger={['click']}
              >
                <Button
                  shape='circle'
                  icon={<MoreOptionsIcon />}
                  ghost
                  style={{ border: 'none', width: '24px', height: '24px' }}
                />
              </Dropdown>
            </div>
          )
        )}

        {/* FILES CONNECTED MARKER */}
        {cardType === 'task' && (
          <div
            style={{ flexBasis: '5%', minWidth: '24px', textAlign: 'right' }}
          >
            {Array.isArray(data.files) && data.files.length > 0 ? (
              <FilePdfTwoTone
                style={{ fontSize: 22 }}
                twoToneColor='rgb(108, 114, 157)'
              />
            ) : null}
          </div>
        )}
      </div>
    </StyledCard>
  );
};

export default DataCard;
