import styled from 'styled-components';

interface StyledProps {
  isDarkMode?: boolean;
}

// Wrapper for the entire shortcuts section
export const ShortcutsWrapper = styled.div<StyledProps>`
  display: flex;
  flex-wrap: wrap; /* Allow shortcuts to wrap onto a new line */
  gap: 20px; /* Adjust the gap between cards */
  justify-content: flex-start; /* Align items to the start */
  align-items: stretch; /* Stretch items to take full height */
  border: ${({ isDarkMode }) => (isDarkMode ? '2px solid #2F3049' : 'none')};
  border-radius: 16px;
  width: 100%; /* Take full width of the container */
`;

// Button styled as a card for each shortcut
export const ShortcutButton = styled('div')<StyledProps>`
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#0F0F1A' : '#F5F5F5')};
  border: ${({ isDarkMode }) => (isDarkMode ? 'none' : '2px solid #ECECEC')};
  color: ${({ isDarkMode }) => (isDarkMode ? '#6C729D' : '#656a92')};
  border-radius: 16px;
  width: 350px; /* Take full width */
  height: 120px; /* Adjust height based on content */
  cursor: pointer;
  padding: 20px;
  display: flex;
  justify-content: space-between; /* Space out text and icon */
  align-items: flex-start;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ isDarkMode }) =>
      isDarkMode ? '#34344d' : '#fcfcfc'};
  }
`;

// Container for the shortcut icon
export const IconContainer = styled('div')<StyledProps>`
  background-color: ${({ isDarkMode }) => (isDarkMode ? '#252638' : '#F5F5F5')};
  border: ${({ isDarkMode }) =>
    isDarkMode ? '2px solid #0F0F1A' : '2px solid #ffffff'};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${({ isDarkMode }) => (isDarkMode ? '#ffffff' : '#363853')};
  }
`;

// Title for the shortcuts section
export const ShortcutsTitle = styled('h1')<StyledProps>`
  color: ${({ isDarkMode }) => (isDarkMode ? '#ffffff' : '#363853')};
  font-size: 24px;
  margin: 0;
`;

// Button styled for the "See all" text
export const SeeAllButton = styled.button<StyledProps>`
  background: none;
  border: none;
  color: ${({ isDarkMode }) => (isDarkMode ? '#CBD2D9' : '#363853')};
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  text-decoration: underline;

  &:hover {
    color: ${({ isDarkMode }) => (isDarkMode ? '#ffffff' : '#000')};
  }
`;
