// components/LoginLayout.tsx
import React from 'react';
import {
  StyledContainer,
  FormContainer,
  TopSection,
  BottomImage,
  Footer,
  LogoImage,
} from './Login.styled';
import arnoldSlogo from '../../assets/arnoldSlogo.png';
import arnoldSfooter from '../../assets/arnoldSfooter.png';
import { Outlet } from 'react-router-dom';

const LoginLayout: React.FC = () => {
  return (
    <StyledContainer>
      <TopSection>
        <LogoImage src={arnoldSlogo} alt='Company Logo' />
      </TopSection>

      <FormContainer>
        <Outlet />
      </FormContainer>

      <Footer>
        <BottomImage src={arnoldSfooter} alt='Decorative Bottom Image' />
      </Footer>
    </StyledContainer>
  );
};

export default LoginLayout;
