import React, { useEffect, useState } from 'react';
import {
  theme,
  notification,
  Spin,
  List,
  Drawer,
  Descriptions,
  Tag,
  Button,
  Input,
  Empty,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { setPageInfo } from '../../store/ducks/Global/operations';
import { getAllTasks } from '../../services/tasksDataService/tasks.service';
import DataCard from '../../components/DataCard';
import { formatDate } from '../../utils/cards';
import SearchComponent from '../../components/Search/Search';
import { downloadFile } from '../../services/filesService/filesService.service';
import { getSingleCardData } from '../../services/iPropertyService/iProperty.service';

interface TaskDetailsDto {
  guid: string | null;
  publisherId: string | null;
  companyId: string | null;
  nodeId: string[] | null;
  connectedIPs: string[] | null;
  title: string | null;
  description: string | null;
  category: string[] | null;
  date: number | null;
  deadline: number | null;
  files: string[] | [];
  archived: number | null;
  note: string | null;
  userNotes: string | null;
  lawFirmNotes: string | null;
}

interface IPData {
  guid: string;
  title: string;
  subtitle: string;
  status: string;
  description: string;
  event: { description: string; date: string }[];
  userTags: string[];
}

const TaskComponent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { token } = theme.useToken();
  const isDarkMode =
    useSelector((state: RootState) => state.global.uitheme) === 'dark';
  const [tasksData, setTasksData] = useState<TaskDetailsDto[]>([]);
  const [loading, setLoading] = useState(true);

  const [selectedTask, setSelectedTask] = useState<TaskDetailsDto | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [connectedIPData, setConnectedIPData] = useState<IPData[]>([]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    backgroundColor: isDarkMode ? '#0F0F1A' : '#FCFCFC',
    padding: '40px 36px',
    borderRadius: '10px',
    height: '100%',
    marginBottom: '61px',
  };

  useEffect(() => {
    dispatch(
      setPageInfo({ currentPageName: 'Tasks', numberOfElem: tasksData.length })
    );
  }, [dispatch, tasksData.length]);

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const data: TaskDetailsDto[] = await getAllTasks();
      const activeTasks = data
        .filter((task) => !task.archived)
        .sort((a, b) => (b.date ?? 0) - (a.date ?? 0));
      setTasksData(activeTasks);
    } catch (error: any) {
      notification.error({
        message: 'Request failed',
        description: 'Failed to fetch tasks',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  useEffect(() => {
    const fetchConnectedIPData = async () => {
      if (
        selectedTask &&
        selectedTask.connectedIPs &&
        selectedTask.connectedIPs.length > 0
      ) {
        try {
          const data = await Promise.all(
            selectedTask.connectedIPs.map(async (guid) => {
              // Assume getSingleCardData returns an object with title, subtitle and status.
              const response = await getSingleCardData(guid);
              return {
                guid: guid,
                ...response,
              } as IPData;
            })
          );
          console.log('ipdata', data);
          setConnectedIPData(data);
        } catch (error) {
          notification.error({
            message: 'Error',
            description: 'Failed to fetch IP data',
          });
          setConnectedIPData([]);
        }
      } else {
        setConnectedIPData([]);
      }
    };

    fetchConnectedIPData();
  }, [selectedTask]);

  const openDrawer = (task: TaskDetailsDto) => {
    setSelectedTask(task);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setSelectedTask(null);
    setDrawerOpen(false);
  };

  const filteredTasks = tasksData
    .filter((task) => {
      const titleMatch = (task.title ?? '')
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const descriptionMatch = (task.description ?? '')
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const formattedTaskDate = task.date
        ? formatDate(task.date).toLowerCase()
        : '';
      const formattedDeadline = task.deadline
        ? formatDate(task.deadline).toLowerCase()
        : '';
      const dateMatch = formattedTaskDate.includes(searchTerm.toLowerCase());
      const deadlineMatch = formattedDeadline.includes(
        searchTerm.toLowerCase()
      );

      return titleMatch || descriptionMatch || dateMatch || deadlineMatch;
    })
    .sort((a, b) => (b.date ?? 0) - (a.date ?? 0));

  const handleFileDownload = async (filename: string) => {
    try {
      const response = await downloadFile(filename);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      notification.error({
        message: 'File Download Failed',
        description: `Could not download file ${filename}. Please try again later.`,
      });
    }
  };

  const navigateToPropertyDetails = (ip: IPData) => {
    console.log('ip', ip);
    localStorage.setItem(`selectedPropertyData_${ip.guid}`, JSON.stringify(ip));
    window.open(`/ip/${ip.guid}`, '_blank');
  };

  return (
    <>
      <div>
        <SearchComponent
          onSearch={handleSearch}
          isDarkMode={isDarkMode}
          searchTerm={searchTerm}
          loading={loading}
        />
      </div>
      <div style={contentStyle}>
        {loading ? (
          <Spin tip='Loading...' size='large' style={{ margin: 'auto' }} />
        ) : (
          <>
            <List
              dataSource={filteredTasks}
              split={false}
              renderItem={(item: TaskDetailsDto, index: number) => (
                <List.Item
                  key={item.guid ?? index}
                  style={{ cursor: 'pointer' }}
                  onClick={() => openDrawer(item)}
                >
                  <DataCard
                    data={item}
                    isDarkMode={isDarkMode}
                    cardType='task'
                  />
                </List.Item>
              )}
              locale={{
                emptyText: (
                  <Empty
                    description={
                      <span
                        style={{ color: isDarkMode ? '#a3a7c3' : '#999999' }}
                      >
                        No tasks found
                      </span>
                    }
                  />
                ),
              }}
            />

            {selectedTask && (
              <Drawer
                title={selectedTask.title ?? 'Task Details'}
                placement='right'
                width={500}
                onClose={closeDrawer}
                open={drawerOpen}
              >
                <Descriptions column={1} bordered size='small'>
                  <Descriptions.Item
                    label='Description'
                    style={{ verticalAlign: 'baseline' }}
                  >
                    {selectedTask.description ?? ''}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label='Category'
                    style={{ verticalAlign: 'baseline' }}
                  >
                    {(selectedTask.category ?? []).map((cat, index) => (
                      <Tag key={index}>{cat}</Tag>
                    ))}
                  </Descriptions.Item>
                  <Descriptions.Item label='Date'>
                    {selectedTask.date ? formatDate(selectedTask.date) : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Deadline'>
                    {selectedTask.deadline
                      ? formatDate(selectedTask.deadline)
                      : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label='Notes'>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                      }}
                    >
                      <Input.TextArea
                        value={selectedTask.note ?? ''}
                        disabled
                        autoSize
                        style={{ backgroundColor: '#f5f5f5' }}
                      />
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item
                    label='IP Information'
                    style={{ verticalAlign: 'baseline' }}
                  >
                    <div
                      style={{
                        maxHeight: 360,
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                        paddingRight: 4,
                      }}
                    >
                      {connectedIPData.length > 0 ? (
                        connectedIPData.map((ip, index) => (
                          <div
                            key={index}
                            style={{
                              border: '1px solid #dcdcdc',
                              borderRadius: 4,
                              backgroundColor: '#f7f7f7',
                              boxShadow: '0 1px 1px rgba(0, 0, 0, 0.05)',
                              lineHeight: 1.4,
                              width: '287px',
                              minHeight: '100px', // Fixed height for all cards
                              maxHeight: '100px', // Fixed height for all cards
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              padding: '8px 12px',
                              boxSizing: 'border-box',
                            }}
                          >
                            {/* Top row: status on left, "View details" on right */}
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>
                                {/* Use a Tag or styled text for status */}
                                <span
                                  style={{
                                    backgroundColor: '#e3e8ff',
                                    color: '#4a53ab',
                                    padding: '2px 6px',
                                    borderRadius: 4,
                                    fontWeight: 500,
                                    fontSize: 12,
                                  }}
                                >
                                  {ip.status}
                                </span>
                              </div>
                              <div>
                                <a
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: 'rgb(108, 114, 157)',
                                  }}
                                  onClick={() => navigateToPropertyDetails(ip)}
                                >
                                  View details
                                </a>
                              </div>
                            </div>

                            {/* Middle/Bottom rows: title & subtitle */}
                            <div
                              style={{
                                marginTop: '4px',
                                fontWeight: 'bold',
                                color: 'rgb(101, 106, 146)',
                                wordWrap: 'break-word',
                                display: '-webkit-box',
                                WebkitLineClamp: 1, // show one line (adjust as needed)
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                              }}
                            >
                              {ip.title}
                            </div>
                            <div
                              style={{
                                marginTop: '4px',
                                color: 'rgb(126, 126, 126)',
                                wordWrap: 'break-word',
                                display: '-webkit-box',
                                WebkitLineClamp: 2, // allow up to two lines (adjust as needed)
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                              }}
                            >
                              {ip.subtitle}
                            </div>
                          </div>
                        ))
                      ) : (
                        <span>No IP information available</span>
                      )}
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item label='Connected Files'>
                    {selectedTask.files && selectedTask.files.length > 0 ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '4px',
                        }}
                      >
                        {selectedTask.files.map((file, index) => (
                          <li key={file}>
                            <span
                              style={{
                                color: isDarkMode ? '#A3A7C3' : '#6C729D',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                              }}
                              onClick={() => handleFileDownload(file)}
                            >
                              {file}
                            </span>
                            <br />
                          </li>
                        ))}
                      </div>
                    ) : (
                      <span>No files connected</span>
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Drawer>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TaskComponent;
