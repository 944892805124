import { environment } from '../../environments/environment';

export const login = async (email: string, password: string) => {
  try {
    const response = await fetch(environment.authUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userEmail: email,
        password: password,
      }),
    });

    const data = await response.json();

    if (!data || data.error_message) {
      throw new Error(data.error_message);
    }

    const loginInfo = {
      userId: data.userId,
      accessToken: data.accessToken,
      isAdmin: data.isAdmin,
    };

    localStorage.setItem('loginInfo', JSON.stringify(loginInfo));

    return loginInfo;
  } catch (error) {
    throw error;
  }
};
