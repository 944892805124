import { environment } from '../../environments/environment';
import { notification } from 'antd';

export const requestEmailOtp = async (email: string) => {
  const response = await fetch(`${environment.otpAuthUrl}/email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

  if (!response.ok) {
    throw new Error(`Failed to request OTP: ${response.statusText}`);
  }

  const text = await response.text();
  return text ? JSON.parse(text) : null;
};

export const verifyEmailOtp = async (email: string, otp: string) => {
  const response = await fetch(`${environment.otpAuthUrl}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, otp }),
  });

  if (!response.ok) {
    throw new Error(`Failed to verify OTP: ${response.statusText}`);
  }

  const data = await response.json();

  const loginInfo = {
    userId: data.userId,
    accessToken: data.accessToken,
    isAdmin: data.isAdmin,
  };

  localStorage.setItem('loginInfo', JSON.stringify(loginInfo));

  return loginInfo;
};
