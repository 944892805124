// VerifyOtpPage.tsx
import React, { useState } from 'react';
import { Input, notification } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { verifyEmailOtp } from '../../services/authenticationService/emailOtpService';
import { StyledFormItem, StyledButton } from '../../views/Login/Login.styled';

const VerifyOtpPage = () => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  const handleVerifyOtp = async () => {
    setLoading(true);
    try {
      await verifyEmailOtp(email, otp);
      navigate('/dashboard');
    } catch (error: any) {
      notification.error({
        message: 'OTP Verification Failed',
        description: error.response?.data?.message || error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <button
          onClick={() => navigate('/login')}
          style={{
            background: 'none',
            border: 'none',
            fontSize: '16px',
            cursor: 'pointer',
            color: '#1677ff',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          ← Back
        </button>
      </div>
      <h3>Sign in with Email</h3>
      <p style={{ fontSize: '14px' }}>Enter the OTP sent to: {email}</p>
      <StyledFormItem name='otpCode'>
        <Input
          placeholder='OTP Code'
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />
      </StyledFormItem>
      <StyledButton
        type='primary'
        size='large'
        onClick={handleVerifyOtp}
        loading={loading}
      >
        {loading ? 'Verifying...' : 'Verify'}
      </StyledButton>
    </div>
  );
};

export default VerifyOtpPage;
