export const environment = {
  production: false,
  serverUrl: '',
  otpAuthUrl:
    process.env.REACT_APP_CI_ENVIRONMENT_NAME == 'Develop'
      ? 'https://dev.auth.ipmanagement.io/auth/otp'
      : 'https://auth.ipmanagement.io/auth/otp',
  authUrl:
    process.env.REACT_APP_CI_ENVIRONMENT_NAME == 'Develop'
      ? 'https://dev.auth.ipmanagement.io/auth/login'
      : 'https://auth.ipmanagement.io/auth/login',
  apiUrl:
    process.env.REACT_APP_CI_ENVIRONMENT_NAME == 'Develop'
      ? 'https://dev.api.ipmanagement.io'
      : 'https://api.ipmanagement.io',
  signUpUrl: '',
  application: {},
  config: {
    api: false,
    url: './assets/params/json/crud/',
  },
};

console.log(
  'REACT_APP_CI_ENVIRONMENT_NAME: _ ',
  process.env.REACT_APP_CI_ENVIRONMENT_NAME
);
