import React, { useEffect, useRef, useState, useMemo } from 'react';
import {
  Button,
  PaginationProps,
  Spin,
  notification,
  theme,
  Pagination,
  Select,
  Drawer,
  Tag,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/configureStore';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import SearchComponent from '../../components/Search';
import StyledCard from '../../components/StyledCard';
import { StyledSelect } from './IntellectualProperty.styled';
import {
  setIPCardsData,
  setFilteredIPCardsData,
  setSearchTerm,
  setCurrentPage,
  setItemsPerPage,
  searchIPropertyData,
  setSelectedPropertyData,
  setImageURLs,
} from '../../store/ducks/IProperty/operations';
import { setPageInfo } from '../../store/ducks/Global/operations';
import {
  getCardsData,
  cardImageUrlGeneration,
  getFilteredCardsData,
  updateBusinessTags,
  createBusinessTag,
  getAllBusinessTags,
} from '../../services/iPropertyService/iProperty.service';
import { calculatePaginationOptions } from '../../utils/pagination';
import { generateExcel } from '../../utils/excelUtils';
import { DownloadOutlined } from '@ant-design/icons';
import MultiSelectBar from '../../components/MultiSelectBar';
import {
  IPropertyCard,
  IPropertySingleCard,
} from '../../store/ducks/IProperty/types';

const CustomPaginationWrapper = styled.div`
  .ant-pagination-item {
    color: #a3a7c3;
  }
  .ant-pagination-item a {
    color: #a3a7c3;
  }
  .ant-pagination-item-active {
    background-color: #8589c1;
  }
  .ant-pagination-item-active a {
    color: #a3a7c3;
    color: white;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    color: #a3a7c3;
  }
  .ant-pagination-item:hover a,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #a3a7c3;
  }
  .ant-pagination-item-ellipsis {
    color: #a3a7c3 !important;
  }
`;

interface TagType {
  name: string;
  guid: string;
  isNew?: boolean;
}

type ImageURLs = { [key: string]: { thumbnailURL: string; imageURL: string } };

const IntellectualProperty: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const previousPathRef = useRef<string>(location.pathname);
  const { Option } = Select;
  const { uitheme } = useSelector((state: RootState) => state.global);
  const selectedCompany = useSelector(
    (state: RootState) => state.global.selectedCompany
  );
  const lastSelectedCompanyRef = useRef<string | null>(selectedCompany);
  const isDarkMode = uitheme === 'dark';

  const { token } = theme.useToken();
  const [selectedPropertyData, setSelectedPropertyDataState] =
    useState<IPropertySingleCard | null>(null);

  // Redux state selection
  const {
    searchResults,
    searchTerm,
    currentPage,
    itemsPerPage,
    cardsData,
    filteredData,
  } = useSelector((state: RootState) => state.iProperty) || {};
  const [loading, setLoading] = useState(true);
  const { imageURLs } = useSelector((state: RootState) => state.iProperty);
  const [paginationOptions, setPaginationOptions] = useState<number[]>([]);

  const {
    userData: { publisherId },
  } = useSelector((state: RootState) => state.global);

  const contentStyle: React.CSSProperties = {
    color: isDarkMode ? '#ffffff' : '#0f0f1a',
    width: 'auto',
    backgroundColor: uitheme === 'dark' ? '#0F0F1A' : '#FCFCFC',
    padding: '61px 26px',
    borderRadius: '10px',
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(300px, 1fr))`,
    gap: '26px',
  };

  const [selectedCards, setSelectedCards] = useState<string[]>([]);

  // Toggle selection for a single card
  const handleToggleSelectCard = (guid: string) => {
    setSelectedCards((prev) =>
      prev.includes(guid) ? prev.filter((id) => id !== guid) : [...prev, guid]
    );
  };

  // “Select all” for the cards on the current page (paginatedCards)
  const handleSelectAll = () => {
    const allOnPage = paginatedCards.map((c: any) => c.ipMetadataGuid);
    setSelectedCards(allOnPage);
  };

  // Clear all selections
  const handleClearSelection = () => {
    setSelectedCards([]);
  };

  // --- Bulk Edit Drawer state ---
  const [allBusinessTags, setAllBusinessTags] = useState<TagType[]>([]);
  const [businessLabels, setBusinessLabels] = useState<TagType[]>([]);
  const [draftBusinessLabels, setDraftBusinessLabels] = useState<TagType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [showBusinessLabelsPanel, setShowBusinessLabelsPanel] = useState(false);

  useEffect(() => {
    if (showBusinessLabelsPanel) {
      const fetchTags = async () => {
        try {
          const response = await getAllBusinessTags();
          const tagMap = new Map<string, TagType>();

          response.forEach((tag: any) => {
            const lowerCaseName = tag.name.toLowerCase();
            if (!tagMap.has(lowerCaseName)) {
              tagMap.set(lowerCaseName, { name: tag.name, guid: tag.guid });
            }
          });

          const uniqueTags = Array.from(tagMap.values());
          setAllBusinessTags(uniqueTags);
        } catch (error: any) {
          notification.error({
            message: 'Failed to fetch business tags',
            description: error.message,
          });
        }
      };
      fetchTags();
    }
  }, [showBusinessLabelsPanel]);

  // Derive available tags from the selected cards (union of their userTags)
  const availableTags: TagType[] = useMemo(() => {
    if (cardsData && selectedCards.length > 0) {
      const selectedCardsData = cardsData.filter((card: any) =>
        selectedCards.includes(card.ipMetadataGuid)
      );

      const tagMap = new Map<string, TagType>();
      selectedCardsData.forEach((card: any) => {
        if (card.userTags && Array.isArray(card.userTags)) {
          card.userTags.forEach((tag: any) => {
            const key = String(tag.name).toLowerCase();
            if (!tagMap.has(key)) {
              tagMap.set(key, { name: String(tag.name), guid: tag.guid });
            }
          });
        }
      });

      console.log('Available Tags:', Array.from(tagMap.values())); // <-- Debugging log
      return Array.from(tagMap.values());
    }
    return [];
  }, [cardsData, selectedCards]);

  // Initialize draft tags from available tags when bulk edit Drawer opens
  useEffect(() => {
    console.log('Show Panel:', showBusinessLabelsPanel);
    console.log('Available Tags inside Effect:', availableTags);

    if (showBusinessLabelsPanel) {
      setBusinessLabels(availableTags);
      setDraftBusinessLabels(availableTags);
    }
  }, [showBusinessLabelsPanel, availableTags]);
  // Keep track of the previous path
  useEffect(() => {
    // Update previousPath reference on every route change
    const previousPath = previousPathRef.current;
    previousPathRef.current = location.pathname;

    // Only remove searchTerm from local storage and reset Redux when navigating away from `/ip`
    // but not when moving to a detail view (e.g., `/ip/some-id`)
    if (
      previousPath.startsWith('/ip') &&
      !location.pathname.startsWith('/ip')
    ) {
      console.log(
        'Navigating away from /ip, clearing searchTerm from localStorage and Redux store'
      );
      localStorage.removeItem('searchTerm');
      dispatch(setSearchTerm('')); // Clear the searchTerm from Redux store
      dispatch(setFilteredIPCardsData([])); // Clear the filtered IP cards data from Redux store
    }
  }, [location.pathname, dispatch]);

  // Additionally, ensure that searchTerm gets cleared when the component unmounts entirely
  useEffect(() => {
    return () => {
      // Check that we are navigating away entirely and not to a detailed IP path
      if (!location.pathname.startsWith('/ip')) {
        console.log(
          'Component unmounted, removing searchTerm from localStorage'
        );
        localStorage.removeItem('searchTerm');
        dispatch(setSearchTerm(''));
      }
    };
  }, [dispatch]);

  const isLatin = (str: string | undefined) => {
    return str?.charAt(0) ? /^[A-Za-z]/.test(str.charAt(0)) : false;
  };

  const sortCardsData = (data: IPropertyCard[]) => {
    return data.sort((a, b) => {
      const titleA = a.card?.title || ''; // Default to an empty string if title is undefined
      const titleB = b.card?.title || ''; // Default to an emp

      const isLatinA = isLatin(titleA);
      const isLatinB = isLatin(titleB);

      if (isLatinA && !isLatinB) return -1;
      if (!isLatinA && isLatinB) return 1;
      return a.card.title.localeCompare(b.card.title);
    });
  };

  const fetchCards = async () => {
    setLoading(true);

    try {
      const params = new URLSearchParams(location.search);
      const filter = params.get('filter');
      const queryParams = new URLSearchParams();
      queryParams.append('publisherId', publisherId);
      if (filter) {
        queryParams.append('tags', filter);
      }

      const queryString = queryParams.toString();

      let data: IPropertyCard[] = [];
      if (filter) {
        data = await getFilteredCardsData(queryString);
        data = sortCardsData(data);
        dispatch(setFilteredIPCardsData(data));
      } else if (
        !cardsData ||
        cardsData.length === 0 ||
        selectedCompany !== lastSelectedCompanyRef.current
      ) {
        data = await getCardsData();
        data = sortCardsData(data);
        dispatch(setIPCardsData(data));
        lastSelectedCompanyRef.current = selectedCompany;
      }

      if (searchTerm) {
        dispatch(searchIPropertyData(searchTerm));
      } else {
        await fetchImages(data);
      }
    } catch (error: any) {
      notification.error({
        message: 'Request failed',
        description: error.message || error.toString(),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCards();
    dispatch(setCurrentPage(1));
    return () => {
      dispatch(setFilteredIPCardsData([]));
    };
  }, [
    dispatch,
    location.search,
    selectedCompany,
    searchTerm,
    cardsData.length,
  ]);

  useEffect(() => {
    dispatch(
      setPageInfo({
        currentPageName: 'IP management',
        numberOfElem:
          searchResults.results.length > 0
            ? searchResults.results.length
            : (filteredData || cardsData).length,
      })
    );
  }, [dispatch, searchResults.results.length, cardsData.length, filteredData]);

  useEffect(() => {
    const totalItems = searchResults.results.length
      ? searchResults.results.length
      : filteredData.length || cardsData.length;

    if (totalItems > 0) {
      const options = calculatePaginationOptions(totalItems);
      setPaginationOptions(options);
    }
  }, [searchResults.results.length, filteredData.length, cardsData.length]);

  const handleSearch = (searchTerm: string) => {
    localStorage.setItem('searchTerm', searchTerm);
    dispatch(setSearchTerm(searchTerm));
    dispatch(searchIPropertyData(searchTerm));
  };

  const fetchImages = async (data: IPropertyCard[]) => {
    const urls: ImageURLs = {};

    await Promise.all(
      data.map(async (card) => {
        if (card.card.thumbnail) {
          try {
            const response = await cardImageUrlGeneration(card.card.thumbnail);
            urls[card.ipMetadataGuid] = {
              imageURL: response.imageURL || '',
              thumbnailURL: response.thumbnailURL || '',
            };
          } catch (error) {
            notification.error({
              message: 'Image Fetch Failed',
              description: `Could not fetch images for ${card.card.title}. Please try again later.`,
            });
          }
        }
      })
    );

    dispatch(setImageURLs(urls));
  };

  const navigateToPropertyDetails = (property: IPropertySingleCard) => {
    setSelectedPropertyDataState(property);
    dispatch(setSelectedPropertyData(property));
    localStorage.setItem(
      `selectedPropertyData_${property.ipMetadataGuid}`,
      JSON.stringify(property)
    );
    navigate(`/ip/${property.ipMetadataGuid}`);
  };

  const handlePageChange: PaginationProps['onChange'] = (page) => {
    dispatch(setCurrentPage(page));
  };

  const handleItemsPerPageChange = (value: number) => {
    dispatch(setItemsPerPage(value));
  };

  const dataToDisplay = searchTerm.trim()
    ? searchResults.results
    : filteredData.length
    ? filteredData
    : filteredData && cardsData;

  const startIndex = Math.max((currentPage - 1) * itemsPerPage, 0);
  const paginatedCards = dataToDisplay.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const newTags = useMemo(() => {
    return draftBusinessLabels.filter(
      (tag) =>
        !availableTags.some(
          (existingTag) =>
            existingTag.name.toLowerCase() === tag.name.toLowerCase()
        ) &&
        !businessLabels.some(
          (assignedTag) =>
            assignedTag.name.toLowerCase() === tag.name.toLowerCase()
        ) &&
        !tag.guid // Ensure it's not an already assigned tag
    );
  }, [draftBusinessLabels, availableTags, businessLabels]);

  const handleSaveBusinessTags = async () => {
    // For bulk edit, update each selected card.
    if (selectedCards.length === 0) return;

    // Identify new tags (those with empty guid)
    const newTags = draftBusinessLabels.filter(
      (tag) => !tag.guid || tag.guid.trim() === ''
    );
    let finalTags = [...draftBusinessLabels];
    if (newTags.length > 0) {
      try {
        const createdTagsArr = await Promise.all(
          newTags.map((tag) =>
            createBusinessTag({
              name: tag.name,
              tagGroupGuid: '8e529fa8-7d29-40b3-b105-58f92ab8acec',
            })
          )
        );
        finalTags = draftBusinessLabels.map((tag) => {
          if (!tag.guid || tag.guid.trim() === '') {
            const created = createdTagsArr.find(
              (ct: any) => ct.name.toLowerCase() === tag.name.toLowerCase()
            );
            return created ? { name: tag.name, guid: created.guid } : tag;
          }
          return tag;
        });
        setDraftBusinessLabels(finalTags);
      } catch (error: any) {
        notification.error({
          message: 'Failed to create new business tags',
          description: error.message,
        });
        return;
      }
    }
    const tagIds = finalTags.map((tag) => tag.guid);
    try {
      // For each selected card, update its tags concurrently.
      await Promise.all(
        selectedCards.map((cardGuid) => updateBusinessTags(tagIds, cardGuid))
      );
      notification.success({ message: 'Business tags updated successfully' });
      // Refetch cards after updating tags.
      fetchCards();
      setShowBusinessLabelsPanel(false);
    } catch (error: any) {
      notification.error({
        message: 'Failed to update business tags',
        description: error.message,
      });
    }
  };

  return (
    <>
      <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
        {!selectedPropertyData && (
          <SearchComponent
            onSearch={handleSearch}
            isDarkMode={isDarkMode}
            searchTerm={searchTerm}
            loading={loading}
          />
        )}
        <div style={{ fontWeight: 'bold', color: '#A3A7C3' }}>
          Display per page:
        </div>
        <StyledSelect
          size='large'
          value={itemsPerPage}
          style={{ width: '100px' }}
          onChange={(value: any) => handleItemsPerPageChange(value)}
          disabled={loading}
        >
          {paginationOptions.length > 0 &&
            paginationOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
        </StyledSelect>
        <div style={{ marginLeft: 'auto' }}>
          <Button
            onClick={() => generateExcel(cardsData)}
            disabled={loading}
            icon={<DownloadOutlined />}
            style={{
              borderRadius: '29px',
              height: '40px',
              width: 'auto',
              padding: '10px 17px',
              borderColor: '#cacac',
              color: loading ? 'gray' : '#6C729D',
              fontSize: '16px',
              backgroundColor: isDarkMode ? '#0f0f1a' : '#ffffff',
              border: isDarkMode ? '1px solid #656A92' : '#cacac',
              marginRight: '20px',
            }}
          >
            Download Excel
          </Button>
          <Button
            onClick={() => navigate('/ip/insights')}
            disabled={loading}
            style={{
              borderRadius: '29px',
              height: '40px',
              width: '100px',
              padding: '10px 17px',
              borderColor: '#cacac',
              color: loading ? 'gray' : '#6C729D',
              fontSize: '16px',
              backgroundColor: isDarkMode ? '#0f0f1a' : '#ffffff',
              border: isDarkMode ? '1px solid #656A92' : '#cacac',
            }}
          >
            Insights
          </Button>
        </div>
      </div>

      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100%',
          }}
        >
          <Spin size='large' />
        </div>
      ) : (
        <>
          <div style={contentStyle}>
            {paginatedCards.length > 0 ? (
              paginatedCards.map((card: any, index) => (
                <StyledCard
                  key={index}
                  isDarkMode={isDarkMode}
                  property={card}
                  thumbnailURL={
                    imageURLs[card.ipMetadataGuid as string]?.thumbnailURL ||
                    null
                  }
                  // Indicate if this card is selected
                  isSelected={selectedCards.includes(card.ipMetadataGuid)}
                  // Toggle selection on circle button click
                  onToggleSelect={() =>
                    handleToggleSelectCard(card.ipMetadataGuid)
                  }
                  navigateToPropertyDetails={() =>
                    navigateToPropertyDetails(card)
                  }
                />
              ))
            ) : (
              <p>No results found.</p>
            )}
          </div>

          {dataToDisplay.length > itemsPerPage && (
            <CustomPaginationWrapper>
              <Pagination
                current={currentPage}
                total={dataToDisplay.length}
                pageSize={itemsPerPage}
                onChange={handlePageChange}
                showSizeChanger={false}
                style={{ marginBottom: '20px' }}
              />
            </CustomPaginationWrapper>
          )}
        </>
      )}

      {/* Show MultiSelectBar if there's at least one selected card */}
      {selectedCards.length > 0 && (
        <MultiSelectBar
          selectedCount={selectedCards.length}
          onSelectAll={handleSelectAll}
          onClearSelection={handleClearSelection}
          // optional: pass callbacks for Edit fields / Change status
          onEditFields={() => setShowBusinessLabelsPanel(true)} // Open the drawer on Edit fields click
        />
      )}
      {/* Drawer for editing business labels */}
      <Drawer
        title='Edit Business Labels'
        placement='right'
        maskClosable={false}
        onClose={() => {
          setDraftBusinessLabels([]); // reset on close
          setShowBusinessLabelsPanel(false);
        }}
        visible={showBusinessLabelsPanel}
        width={400}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <div>
            <Select
              mode='tags'
              style={{ width: '100%' }}
              placeholder='Enter or select labels'
              value={draftBusinessLabels.map((label) => label.name)}
              onChange={(value: string[]) => {
                const updated = value.map((tagName: string) => {
                  const existing = allBusinessTags.find(
                    (tag) => tag.name.toLowerCase() === tagName.toLowerCase()
                  );
                  const alreadyDraft = draftBusinessLabels.find(
                    (t) => t.name.toLowerCase() === tagName.toLowerCase()
                  );

                  return existing
                    ? existing
                    : alreadyDraft
                    ? alreadyDraft
                    : { name: tagName, guid: '', isNew: true };
                });

                setDraftBusinessLabels(updated);
              }}
              // options={allBusinessTags.map((tag) => ({
              //   value: tag.name,
              //   label: tag.name,
              // }))}
              onSearch={(val) => setSearchValue(val)}
              filterOption={(input, option) =>
                (option?.value?.toString() || '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              // onBlur={() => {
              //   // Only remove a pending (unconfirmed) tag if searchValue exists.
              //   if (searchValue) {
              //     setDraftBusinessLabels((prev) =>
              //       prev.filter(
              //         (tag) =>
              //           !(
              //             tag.isNew &&
              //             tag.name.toLowerCase() === searchValue.toLowerCase()
              //           )
              //       )
              //     );
              //     setSearchValue('');
              //   }
              // }}
              onInputKeyDown={(e) => {
                if (e.key === 'Enter' && searchValue) {
                  if (
                    !allBusinessTags.some(
                      (tag) =>
                        tag.name.toLowerCase() === searchValue.toLowerCase()
                    ) &&
                    !draftBusinessLabels.some(
                      (label) =>
                        label.name.toLowerCase() === searchValue.toLowerCase()
                    )
                  ) {
                    e.preventDefault();
                    setDraftBusinessLabels([
                      ...draftBusinessLabels,
                      { name: searchValue, guid: '', isNew: true },
                    ]);
                  }
                  setSearchValue('');
                }
              }}
              tagRender={(props) => {
                const { label, value, closable, onClose } = props;
                const found = draftBusinessLabels.find(
                  (lbl) =>
                    (lbl.name || '').toLowerCase() ===
                    (value || '').toLowerCase()
                );
                const isNew = found?.isNew;
                return (
                  <Tag
                    color={isNew ? 'blue' : 'default'}
                    closable={closable}
                    onClose={onClose}
                    style={{
                      margin: '3px',
                      fontSize: '14px',
                    }}
                  >
                    {label}
                  </Tag>
                );
              }}
              options={[
                // If the searchValue doesn't match an existing tag, show a "(New)" option.
                ...(searchValue &&
                !allBusinessTags.some(
                  (tag) => tag.name.toLowerCase() === searchValue.toLowerCase()
                )
                  ? [
                      {
                        value: searchValue,
                        label: (
                          <span
                            style={{
                              color: 'rgb(24, 144, 255)',
                              fontWeight: 'bold',
                            }}
                          >
                            {searchValue} (New)
                          </span>
                        ),
                      },
                    ]
                  : []),
                // List all available tags.
                ...allBusinessTags.map((tag) => ({
                  value: tag.name,
                  label: tag.name,
                })),
              ]}
            />
          </div>
          <div
            style={{
              marginTop: 'auto',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              type='primary'
              style={{
                borderRadius: '29px',
                fontSize: '16px',
                padding: '10px 17px',
                backgroundColor: 'rgb(108, 114, 157)',
              }}
              onClick={handleSaveBusinessTags}
            >
              Save
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default IntellectualProperty;
