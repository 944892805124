import styled from 'styled-components';
import { Button, Menu, MenuProps } from 'antd';

interface StyledMenuItemProps extends MenuProps {
  collapsed?: boolean;
  isDarkMode?: boolean;
}

export const UserInfoContainer = styled.div`
  display: flex;
  align-items: center; /* Centers vertically */
`;

export const UserName = styled.span<StyledMenuItemProps>`
  font-family: 'Overpass', sans-serif;
  margin-right: 8px;
  color: ${({ isDarkMode }) => (isDarkMode ? 'white' : 'gray')};
  text-align: left;
`;

export const UserInfo = styled.div`
  font-weight: bold;
  font-size: 14px;
  font-family: 'Noto Serif TC';
  color: #565656;
  display: flex;
  flex-direction: column; /* Vertically align text */
  justify-content: center;
  line-height: 1.2; /* Adjust line height for spacing */
`;

export const UserEmail = styled.div`
  font-size: 14px;
  color: #666;
`;
export const StyledMenuItem = styled(Menu.Item)<StyledMenuItemProps>`
  && {
    transition: background 0.3s, color 0.3s;
    background: ${({ isDarkMode }) => (isDarkMode ? '#313349' : 'white')};
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 82px;
    margin: 0;
    border-radius: 0;
    width: auto;
    justify-content: center;
    gap: 5px;
  }

  &:hover,
  &:active,
  &:focus {
    background: #313349;
  }

  &.ant-menu-item-selected {
    background: ${({ isDarkMode }) => (isDarkMode ? 'none' : '#00a787')};
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    .ant-menu-title-content {
      font-weight: 500;
      display: ${({ collapsed }) =>
        collapsed ? 'none' : 'inline-block'} !important;
      margin: 0 !important;
      color: ${({ isDarkMode }) => (isDarkMode ? '#00a787' : '#FFFFFF')};
      font-size: 16px;
      line-height: 24px;
    }
  }

  /* Styles for the non-selected state */
  .ant-menu-title-content {
    font-weight: 500;
    display: ${({ collapsed }) =>
      collapsed ? 'none' : 'inline-block'} !important;
    margin: 0 !important;
    color: #6c729d;
    font-size: 16px;
    line-height: 24px;
  }
`;
