import React from 'react';
import { notification } from 'antd';
import {
  ActionItem,
  CenterSection,
  CountBadge,
  LeftSection,
  MultiSelectBarWrapper,
  RightSection,
  Separator,
} from './MultiSelectBar.styled';
import {
  CheckCircleOutlined,
  EditOutlined,
  CloseOutlined,
} from '@ant-design/icons';

interface MultiSelectBarProps {
  selectedCount: number;
  onSelectAll: () => void;
  onClearSelection: () => void;
  onEditFields?: () => void; // optional callback for "Edit fields"
}

const MultiSelectBar: React.FC<MultiSelectBarProps> = ({
  selectedCount,
  onSelectAll,
  onClearSelection,
  onEditFields,
}) => {
  return (
    <MultiSelectBarWrapper>
      {/* Number of selected items */}
      <LeftSection>
        <span>
          <CountBadge>{selectedCount}</CountBadge> selected
        </span>
      </LeftSection>

      <CenterSection>
        <ActionItem onClick={onSelectAll}>
          <CheckCircleOutlined />
          <span>Select all</span>
        </ActionItem>
        <Separator />
        <ActionItem onClick={onEditFields}>
          <EditOutlined />
          <span>Edit fields</span>
        </ActionItem>
      </CenterSection>
      <RightSection>
        <ActionItem onClick={onClearSelection}>
          <CloseOutlined />
        </ActionItem>
      </RightSection>
    </MultiSelectBarWrapper>
  );
};

export default MultiSelectBar;
