import { environment } from '../../environments/environment';
import { fetchWithCompanyId } from '../../utils/fetchWithCompanyId';
import authHeader from '../authenticationService/auth-header';

export const fetchTasksData = async () => {
  const userData = localStorage.getItem('loginInfo');
  let userId = '';
  if (userData) userId = JSON.parse(userData)?.userId;

  try {
    const response = await fetch(
      `${environment.apiUrl}/tasks/instructions/${userId}`,
      {
        headers: authHeader(),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error: any) {
    throw new Error('Error fetching user data: ' + error.message);
  }
};

export const getAllTasks = async () => {
  try {
    return await fetchWithCompanyId('/taskDetails', {
      headers: authHeader(),
    });
  } catch (error: any) {
    throw new Error('Error fetching files data: ' + error.message);
  }
};
