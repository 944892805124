import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialPropertyState } from './initialState';
import {
  ImageURLs,
  IPropertyCard,
  IPropertySingleCard,
  IPropertyState,
} from './types';

const IPropertySlice = createSlice({
  name: 'iProperty',
  initialState: initialPropertyState(),
  reducers: {
    set_search_term: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    set_current_page: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    set_items_per_page: (state, action: PayloadAction<number>) => {
      state.itemsPerPage = action.payload;
    },
    search_iProperty: (
      state: IPropertyState,
      action: PayloadAction<string>
    ) => {
      const searchTerms = action.payload
        .trim()
        .toLowerCase()
        .split(/\s+/)
        .filter((term) => term);

      const dataToSearch =
        state.filteredData.length > 0 ? state.filteredData : state.cardsData;

      state.searchResults.results = dataToSearch.filter((item) => {
        const searchableFields = [
          item.card?.title,
          item.card?.subtitle,
          item.card?.description,
          item.card?.status,
          ...(item.card?.event?.map((event: any) => event.description) || []),
          ...(item.card?.event?.map((event: any) => event.date) || []),
          ...(item.card?.userTags?.map((tag: any) => tag.name) || []),
        ].filter(Boolean);

        const foundTerms = searchTerms.filter((term) =>
          searchableFields.some((field) =>
            field?.toString().toLowerCase().includes(term)
          )
        );

        return foundTerms.length === searchTerms.length;
      });

      state.searchResults.results.sort(
        (a, b) => (b.score || 0) - (a.score || 0)
      );
    },

    set_iProperty_data: (
      state: IPropertyState,
      action: PayloadAction<IPropertyCard[]>
    ) => {
      state.cardsData = [...action.payload];
      state.searchResults.results = [];
    },
    set_filtered_data: (
      state: IPropertyState,
      action: PayloadAction<IPropertyCard[]>
    ) => {
      state.filteredData = [...action.payload];
      state.searchResults.results = [];
    },
    set_selected_property_data(
      state: IPropertyState,
      action: PayloadAction<IPropertySingleCard | null>
    ) {
      state.selectedPropertyData = action.payload;
    },
    set_image_urls(state, action: PayloadAction<ImageURLs>) {
      state.imageURLs = {
        ...state.imageURLs,
        ...action.payload,
      };
    },
    set_loading: (state, action) => {
      state.loading = action.payload;
    },
    set_error: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default IPropertySlice;
